import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountService } from '../../../../services/account.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteModalComponent implements OnInit {
  myForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    private account: AccountService,
    private router: Router,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.myForm = new FormGroup({
      'password': new FormControl('', [Validators.required])
    });
  }

  submit() {
    const password = this.myForm.get('password').value;
    this.account.deleteAccount(password).subscribe(
      () => {
        this.activeModal.close();
        this.router.navigate(['/sign-in']);
      },
      err => {
        this.notify.error('Error', err.error ? err.error.message : err.message);
      });

  }

}
