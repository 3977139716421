import { NgModule, ErrorHandler, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthErrorHandler } from "./services/auth-error-handler";
import { FacebookModule } from "ngx-facebook";
import { NgxsModule } from "@ngxs/store";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { DeviceDetectorModule } from "ngx-device-detector";
import { environment } from "../environments/environment";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
/* App Root */
import { AppComponent } from "./app.component";

/* Routing Module */
import { AppRoutingModule } from "./app-routing.module";

/* Navigation Components */
import { HeaderComponent } from "./components/layout/navigation/header/header.component";
import { SidebarComponent } from "./components/layout/sidebar/sidebar.component";
import { SidebarAccountComponent } from "./components/layout/sidebar-account/sidebar-account.component";

/* Services */
import { AuthGuardService } from "./services/auth-guard.service";
import { NotesService } from "./services/notes.service";
import { TagsService } from "./services/tags.service";
import { IntegrationsService } from "./services/integrations.service";
import { ChannelsService } from "./services/channels.service";

/* Notification Module & Service */
import { Options, SimpleNotificationsModule } from "angular2-notifications";

/* Interceptors */
import { HttpClientModule } from "@angular/common/http";

import { NgxStripeModule } from "ngx-stripe";

import { SharedModule } from "./shared/shared.module";
import { IntegrationsModule } from "./modules/integrations/integrations.module";

import { HeaderService } from "./components/actions/header.service";
import { MobileNavComponent } from "./components/layout/navigation/mobile-nav/mobile-nav.component";
import { AccountResolver } from "./resolvers/account.resolver";

//  Editor

import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";

/** Redux */
import {
  AccountState,
  ChannelState,
  TagState,
  NoteState,
  WorkspaceState
} from "./store/state";

/* Google API */
import {
  GoogleApiModule,
  GoogleApiService,
  GoogleAuthService,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
  GoogleApiConfig
} from "ng-gapi";
import { WorkspacesService } from "./services/workspaces.service";
import { WorkspaceResolver } from "./resolvers/workspace.resolver";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { WSDropdownComponent } from "./components/layout/navigation/workspace-dropdown/ws-dropdown.component";
import { UtilService } from "./services/util.service";
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { RedirectGuard } from "./services/redirect-guard.service";
import {
  ResendComponent,
  WSInitialsComponent,
  WsRenameModalComponent,
  WSCreateModalComponent,
  WsIntroComponent,
  WsInviteModalComponent,
  WsMembersModalComponent,
  WSSuccessModalComponent,
} from "./components/layout/navigation/components";
import { WsDropdownSideComponent } from "./components/layout/ws-dropdown-side/ws-dropdown-side.component";
import { SubscriptionsService } from "./services/subscriptions.service";
import { ViewPageComponent } from "./components/layout/view-page/view-page.component";
import { EmptyModule } from "./shared/empty.module";
import { PublishNoteModalComponent } from "./pages/notebook/components/layout/publish-note-modal/publish-note-modal.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ConfirmationModalComponent } from "./shared/ui-components/confirmation-modal/confirmation-modal.component";
import { EbookModalComponent } from "./shared/ui-components/ebook-modal/ebook-modal.component";
import { MoveChannelModalComponent } from "./pages/notebook/components/layout/note-channel/components/move-channel-modal/move-channel-modal.component";
import { NotFoundComponent } from "./pages/onboarding/not-found/not-found.component";
import { ConfirmDeleteModalComponent } from "./pages/account-management/account-profile/components/confirm-delete.component";
import { ConfirmSettingDeleteModalComponent } from "./pages/account-management/setting/components/confirm-delete.component";
import { MoveMultichannelModalComponent } from "./components/layout/navigation/components/move-multichannel-modal/move-multichannel-modal.component";
import { UsernameModalComponent } from "./components/layout/navigation/components/username-modal/username-modal.component";
import { YacModalComponent } from './components/layout/navigation/components';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import { AngularFireModule } from '@angular/fire';
import { ClientService } from "@services/client.service";
import { SharesService } from "@services/shares.service";
import { ConnectIntegrationModalComponent } from "./components/integration-modals/connect/connect-integration-modal.component";
import { DisconnectIntegrationModalComponent } from "./components/integration-modals/disconnect/disconnect-integration-modal.component";
import { DataIntegrationsService } from "@services/data-integrations.service";

import {OnboardingModalComponent} from '@shared/ui-components/onboarding-modal/onboarding-modal.component';
import { SubscriptionModalComponent } from "@shared/ui-components/subscription-modal/subscription-modal.component";

const gapiClientConfig: NgGapiClientConfig = {
  client_id: environment.googleClientId,
  discoveryDocs: environment.googleApiDiscoveryDocs,
  scope: environment.googleApiScope.join(" ")
};

const toInclude = !environment.production
  ? [
      NgxsLoggerPluginModule.forRoot({ collapsed: false, logger: console })
    ]
  : [];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarAccountComponent,
    MobileNavComponent,
    WSDropdownComponent,
    WsMembersModalComponent,
    WsRenameModalComponent,
    WsInviteModalComponent,
    WSCreateModalComponent,
    WsIntroComponent,
    WSInitialsComponent,
    WsDropdownSideComponent,
    WSSuccessModalComponent,
    ViewPageComponent,
    ResendComponent,
    PublishNoteModalComponent,
    UsernameModalComponent,
    ConfirmationModalComponent,
    EbookModalComponent,
    OnboardingModalComponent,
    MoveChannelModalComponent,
    ConfirmDeleteModalComponent,
    ConfirmSettingDeleteModalComponent,
    NotFoundComponent,
    MoveMultichannelModalComponent,
    ConnectIntegrationModalComponent,
    DisconnectIntegrationModalComponent,
    YacModalComponent,
    SubscriptionModalComponent,
  ],
  imports: [
    FontAwesomeModule,
    FormsModule,
    SharedModule.forRoot(),
    IntegrationsModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FacebookModule.forRoot(),
    SimpleNotificationsModule.forRoot(<Options>environment.toastOptions),
    HttpClientModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    NgbModule.forRoot(),
    NgxsModule.forRoot(
      [AccountState, ChannelState, TagState, NoteState, WorkspaceState],
      { developmentMode: !environment.production }
    ),
    NgxStripeModule.forRoot(environment.stripeKey),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireFunctionsModule,
    DeviceDetectorModule.forRoot(),
    EmptyModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    SocialLoginModule,
    ...toInclude
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AuthErrorHandler
    },
    AuthGuardService,
    ChannelsService,
    NotesService,
    TagsService,
    IntegrationsService,
    HeaderService,
    AccountResolver,
    WorkspaceResolver,
    WorkspacesService,
    SubscriptionsService,
    UtilService,
    ClientService,
    RedirectGuard,
    SharesService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId
            )
          },
        ]
      } as SocialAuthServiceConfig,
    },
    DataIntegrationsService,
    NgbActiveModal,
  ],
  entryComponents: [
    WsRenameModalComponent,
    WsMembersModalComponent,
    WsInviteModalComponent,
    PublishNoteModalComponent,
    WSCreateModalComponent,
    WSSuccessModalComponent,
    UsernameModalComponent,
    ConfirmationModalComponent,
    EbookModalComponent,
    OnboardingModalComponent,
    ConfirmSettingDeleteModalComponent,
    MoveChannelModalComponent,
    ConfirmDeleteModalComponent,
    MoveMultichannelModalComponent,
    ConnectIntegrationModalComponent,
    DisconnectIntegrationModalComponent,
    YacModalComponent,
    SubscriptionModalComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
