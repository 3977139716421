import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Workspace, Account } from '../../../interfaces';
import { WorkspaceStateModel } from '../../../store/state/workspace.state';
import { UtilService } from '../../../services/util.service';
import { WSCreateModalComponent } from '../navigation/components';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ws-dropdown-side',
  templateUrl: './ws-dropdown-side.component.html',
  styleUrls: ['./ws-dropdown-side.component.scss']
})
export class WsDropdownSideComponent implements OnInit, OnDestroy {
  @Input() account: Account;
  subscription = new Subscription();
  active: Workspace;
  list: Array<Workspace>;
  isActive = new Array<boolean>();

  constructor(
    private _store: Store,
    private modalService: NgbModal,
    private _utilService: UtilService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription.add(this._store.select(state => state.workspace)
      .subscribe((wsState: WorkspaceStateModel) => {
        this.active = wsState.active;
        this.list = wsState.workspaces;
        this.isActive = this.list.map(value => {
          return !value.status || value.status === 'active';
        });
      })
    );
  }

  clearActive() {
    this._utilService.clearActiveRedux();
  }

  openWsCreationModal() {
    const modalRef = this.modalService.open(WSCreateModalComponent, { windowClass: 'mt-z zoomIn animated workspace', backdrop: false, size: 'lg' });
    modalRef.componentInstance.account = this.account;
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onWsClick(workspace: Workspace, active: boolean, drop: any) {
    if (active && this.active._id !== workspace._id) {
      this.router.navigate(['/', workspace._id, 'notebook']);
      drop.close();
    }
  }
}
