import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import test from './test/module';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ModulesService {

  private _baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getConfig(): Observable<Object> {
    return this.http.get(`${this._baseUrl}/config`);
  }

  getModules(params: any): Observable<any> {
    return this.http.get(`${this._baseUrl}/accounts/me/modules`, {params: params});
  }

  getModuleById(id: string, params: any): Observable<Object> {
   return this.http.get(`${this._baseUrl}/accounts/me/modules/${id}`, {params: params});
  }

  // authenticateGACode(body: any): Observable<Object> {
  //   return this.http.post(`${this._baseUrl}/accounts/me/modules/google/auth`, body);
  // }

  createModule(body: any): Observable<Object> {
    return this.http.post(`${this._baseUrl}/accounts/me/modules`, body);
  }

  updateModuleById(id: string, body: any): Observable<Object> {
    return this.http.post(`${this._baseUrl}/accounts/me/modules/${id}`, body);
  }

  // getProfiles(): Observable<Object> {
  //   return this.http.get(`${this._baseUrl}/accounts/me/profiles`);
  // }

  // removePage(id: string): Observable<Object> {
  //   return this.http.post(`${this._baseUrl} /accounts/me/profiles/remove`, { id: id });
  // }

  list(): Observable<any> {
    return Observable.create( (observer) => {
      const list = observer.next([{name: 'integration'}]);
      observer.complete();
    });
  }

}
