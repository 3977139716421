import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataIntegrationsService } from '@services/data-integrations.service';
import { NotificationService } from '@services/notification.service';
import { BehaviorSubject } from 'rxjs';

const MAX_UPLOAD_SIZE = 100 * 1024 * 1024;

@Component({
  selector: 'app-ebook-modal',
  templateUrl: './ebook-modal.component.html',
  styleUrls: ['./ebook-modal.component.scss']
})
export class EbookModalComponent {

  files: File[] = [];
  uploadSize = 0;
  uploadedPercentage = 0;
  uploading = false;
  readonly _validContentTypes = ['application/epub+zip', 'application/pdf'];
  hasPaidSubscription = null;

  public get canUpload(): boolean {
    return this.files.length > 0 && this.uploadSize <= MAX_UPLOAD_SIZE && this.hasPaidSubscription;
  }

  public get validContentTypes(): string {
    return this._validContentTypes.join(', ')
  }

  constructor(
    private _notifications: NotificationService,
    public activeModal: NgbActiveModal,
    public integrationsService: DataIntegrationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkSubscriptionStatus();
  }

  handleFileInput(files: FileList) {
    Array.from(files).forEach(
      (file) => {
        if (this._validContentTypes.includes(file.type) &&
          this.uploadSize < MAX_UPLOAD_SIZE) {
          this.files.push(file);
          this.uploadSize += file.size;
        }
      }
    );
  }

  deleteAttachment(index: number) {
    this.uploadSize -= this.files[index].size;
    this.files.splice(index, 1);
  }

  clearModalData(): void {
    this.files = [];
    this.uploadSize = 0;
    this.uploading = false;
    this.uploadedPercentage = 0;
  }

  uploadingHandler (event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        this.uploadedPercentage = Math.round(100 * event.loaded / event.total);
        console.log(`File is ${this.uploadedPercentage}% uploaded.`);
      case HttpEventType.Response:
        if (event instanceof HttpResponse) {
          this._notifications.success('Uploaded', `${this.files.length} Files successfully uploaded`, '');
          setTimeout(() => {
            this.router.navigate(['/', (event as HttpResponse<any>).body.workspaceId, 'notebook']);
            this.clearModalData();
          }, 1000);
          this.activeModal.close();
        }
        break;
    }
  }

  uploadFiles() {
    if (this.files.length > 0) {
      this.uploading = true;
      this.integrationsService.postFiles(this.files)
      this.activeModal.close();
    }
  }

  getFileSize(file: File): String {
    if (file && file.size > 0) {
      const size = (file.size / 1024) / 1024;
      return `${size.toFixed(3)} mb`;
    }

    return '';
  }

  checkSubscriptionStatus() {
    this.integrationsService
      .getMergedIntegrations()
      .subscribe(() => {
        this.hasPaidSubscription = true;
      }, err => {
        if (err.status === 428) {
          this.hasPaidSubscription = false;
        }
      });
  }

}
