import { Component, ElementRef, Input, Output, ViewChild, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  template: `<ng-content></ng-content>`
})
export class ModalHeaderComponent {

}

@Component({
  selector: 'app-modal-content',
  template: `<ng-content></ng-content>`
})
export class ModalContentComponent {

}

@Component({
  selector: 'app-modal-footer',
  template: `<ng-content></ng-content>`
})
export class ModalFooterComponent {

}

@Component({
  selector: 'app-modal-footer-left',
  template: `<ng-content></ng-content>`
})
export class ModalFooterLeftComponent {

}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnDestroy {

    @Input()
    public modalClass: string;

    @Input()
    public closeOnEscape = true;

    @Input()
    public closeOnOutsideClick = true;

    @Input()
    public title: string;

    @Input()
    public hideCloseButton = false;

    @Input()
    public cancelButtonLabel: string;

    @Input()
    public submitButtonLabel: string;

    @Input()
    public backdrop = true;


    @Output()
    public onOpen = new EventEmitter(false);

    @Output()
    public onClose = new EventEmitter(false);

    @Output()
    public onCancel = new EventEmitter(false);

    @Output()
    public onSubmit = new EventEmitter(false);


    public isOpened = false;

    @ViewChild('modalRoot')
    public modalRoot: ElementRef;

    private backdropElement: HTMLElement;

    constructor() {
    }

    ngOnDestroy() {

    }

    open(...args: any[]) {
      if (this.isOpened) {
        return;
      }
      this.isOpened = true;
    }

    close(...args: any[]) {
      if (!this.isOpened) {
        return;
      }
      this.isOpened = false;
      this.onClose.emit(args);
    }

    cancel(...args: any[]) {
      if (!this.isOpened) {
        return;
      }
      this.isOpened = false;
      this.onCancel.emit(args);
    }

    public preventClosing(event: MouseEvent) {
      event.stopPropagation();
    }

}
