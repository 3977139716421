import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetCurrentUser } from '../actions/account.action';
import { Account } from '../../interfaces';

@State<Account>({
  name: 'account',
  defaults: {
    _id: undefined,
    firstName: undefined,
    lastName: undefined,
    promoCode: undefined,
    password: undefined,
    username: undefined,
    email: undefined,
    notifications: {
      newsletter: undefined
    },
    status: undefined,
    verified: {
      email: undefined
    },
    mobile: undefined,
    address: undefined,
    cancelAt: undefined,
    cancelled: undefined,
    lastLoginAt: undefined,
    createdAt: undefined,
    subscribed: undefined,
    planId: undefined,
    'recentChannelInvites': [{
      '_id': undefined,
      'email': undefined,
      'firstName': undefined,
      'lastName': undefined,
    }],
  }
})
export class AccountState {
  @Action(SetCurrentUser)
  SetCurrentUser(ctx: StateContext<Account>, { payload }: SetCurrentUser) {
    ctx.setState(payload);
  }
}
