import { Injectable } from '@angular/core';
import { GoogleAuthService, GoogleApiService } from 'ng-gapi';
import { resolve, reject } from 'q';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

declare var gapi: any;

@Injectable()
export class GoogleService {
  // TAG used for console logs to identify the class/object it is associated with
  private TAG = 'GoogleService';

  private oauthEndpoint = `${environment.apiUrl}/accounts/me/oauth`;

  // all of the following have a getter
  private offlineAccessCode = undefined;
  private userId = '';
  private tokenExpiresAt = '';
  private refreshToken = '';
  private accessToken = '';
  private oauth = '';
  private properties = [];
  private profiles = [];
  private accounts = [];
  private viewList = [
    {
      name: 'All Users',
      viewId: 'ga:users'
    },
    {
      name: 'New Users',
      viewId: 'ga:newUsers'
    },
    {
      name: 'Number Of Sessions',
      viewId: 'ga:sessions'
    },
    {
      name: 'Transactions',
      viewId: 'ga:transactions'
    },
    {
      name: 'Revenue',
      viewId: 'ga:transactionRevenue'
    },
    {
      name: 'Page Views',
      viewId: 'ga:pageviews'
    },
    {
      name: 'Unique Page Views',
      viewId: 'ga:uniquePageviews'
    }
  ];

  constructor(
    private _googleAuthService: GoogleAuthService,
    private _googleApiService: GoogleApiService,
    private _http: HttpClient
  ) { }

  getAccessToken() {
    return this.accessToken;
  }

  getOauth() {
    return this.oauth;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getTokenExpiresAt() {
    return this.tokenExpiresAt;
  }

  getViewList() {
    return this.viewList;
  }

  getPropertiesList() {
    return this.properties;
  }

  /*
  *  call googleApiService.onLoad()
  *  after onLoad you can start the google auth process
  *  request offline access so that out api server can aggrigate data on the users behalf
  */
  signIn(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._googleApiService.onLoad().subscribe(() => {
        const grantOptions = {
          scope: 'https://www.googleapis.com/auth/analytics.readonly'
        };

        this._googleAuthService.getAuth().subscribe((auth) => {
          auth.grantOfflineAccess(grantOptions)
            .then((code) => resolve(this.signInSuccessHandler(code)))
            .catch((error) => reject(this.signInErrorHandler(error)));
        });
      });
    });
  }

  // take list of properties and filter them by selected account and then sort them a - z
  filterProperties(accountId): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.properties = this.properties.filter((prop) => {
          return prop.accountId === accountId;
        });

        this.properties = this.sortArray(this.properties);

        resolve(this.properties);
      } catch (error) {
        reject(error);
      }
    });
  }

  // take list of profiles and filter them by selected account and then sort them a - z
  filterProfiles(propertyId): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.profiles = this.profiles.filter( (profile) => {
          return profile.accountId === propertyId;
        });
        resolve(this.profiles);
      } catch (error) {
        reject(error);
      }
    });
  }

  // this should be called before we create a module so that our api has the proper oauth
  createOAuth() {
    return new Promise((resolve, reject) => {
      const body = { type: 'GOOGLE', code: this.offlineAccessCode };

      // console.log(this.TAG, body);

      this._http.post(this.oauthEndpoint, body).subscribe((response: any) => {
        // console.log(this.TAG, `createOAuth google response: ${JSON.stringify(response, null, 2)}`);

        if (response.oauth) {
          this.oauth = response.oauth;
          this.accessToken = response.oauth.accessToken || this.accessToken;
          this.refreshToken = response.oauth.refreshToken || '';
          this.tokenExpiresAt = response.oauth.tokenExpiresAt || '';
          this.userId = response.oauth.id || '';
        }

        resolve();
      }, (error) => {
        reject(error);
        // console.error(this.TAG, `${JSON.stringify(error, null, 2)}`);
      });
    });
  }

  // when the services slider is closed remove all properties
  dispose() {
    this.offlineAccessCode = undefined;
    this.userId = '';
    this.tokenExpiresAt = '';
    this.refreshToken = '';
    this.accessToken = '';
    this.oauth = '';
    this.properties = [];
    this.profiles = [];
    this.accounts = [];
  }

  private signInSuccessHandler(response): Promise<any> {
    this.offlineAccessCode = response.code;
    return this.fetchAnalytics();
  }

  private signInErrorHandler(err) {
    console.error(this.TAG, `google sign in error: ${JSON.stringify(err, null , 2)}`);
  }

  // series of steps to get accounts, properties, and profiles
  private fetchAnalytics(): Promise<any> {
    return new Promise((resolve, reject) => {
      gapi.load('analytics', () => {
        const accountsPromise: Promise<any> = gapi.client.analytics.management.accounts.list();
        const webpropertiesPromise = gapi.client.analytics.management.webproperties.list(
          {
            accountId: '~all'
          }
        );
        const profilesPromise = gapi.client.analytics.management.profiles.list({
          accountId: '~all',
          webPropertyId: '~all'
        });

        // set profiles, and properties then set format and sort accounts
        Promise.all([webpropertiesPromise, profilesPromise, accountsPromise])
          .then((pResults) => {
            this.properties = pResults[0].result.items;
            this.profiles = pResults[1].result.items;
            this.accounts = pResults[2].result.items;

            this.accounts = pResults[2].result.items.map((account) => {
              return {
                accountId: account.id,
                name: account.name,
                value: account
              };
            });

            this.accounts = this.sortArray(this.accounts);

            resolve(this.accounts);
          });
      });
    });
  }

  // sort objects in array by name property
  private sortArray(array): Array<any> {
    array.sort(function (a, b) {
      return a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
    });

    return array;
  }
}
