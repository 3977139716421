import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountResolver } from './resolvers/account.resolver';
import { AuthGuardService } from './services/auth-guard.service';
import { WorkspaceResolver } from './resolvers/workspace.resolver';
import { RedirectGuard } from './services/redirect-guard.service';
import { ViewPageComponent } from './components/layout/view-page/view-page.component';
import { EmptyComponent } from './shared/ui-components/empty/empty.component';
import { NotFoundComponent } from './pages/onboarding/not-found/not-found.component';


/**
 * Root paths
 */
const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/onboarding/onboarding.module#OnboardingModule'
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'shares',
    component: ViewPageComponent,
    loadChildren: './pages/shares/shared-note/shared-note.module#SharedNoteModule',
    data: {
      page: 'shares'
    }
  },
  {
    path: 'account',
    component: ViewPageComponent,
    loadChildren: './pages/account-management/account-management.module#AccountManagementModule',
    resolve: {
      account: AccountResolver
    },
    canActivate: [AuthGuardService],
    data: {
      page: 'account'
    }
  },
  {
    path: 'notebook',
    canActivate: [RedirectGuard],
    pathMatch: 'full',
    component: EmptyComponent
  },
  {
    path: 'dashboard',
    canActivate: [RedirectGuard],
    pathMatch: 'full',
    component: EmptyComponent
  },
  {
    path: ':workspace',
    canActivate: [AuthGuardService],
    resolve: {
      account: AccountResolver,
      workspace: WorkspaceResolver
    },
    runGuardsAndResolvers: 'paramsChange',
    children: [
      {
        path: 'dashboard',
        component: ViewPageComponent,
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
        data: {
          page: 'dashboard'
        }
      },
      {
        path: 'notebook',
        component: ViewPageComponent,
        loadChildren: './pages/notebook/notebook.module#NotebookModule',
        data: {
          page: 'notebook'
        }
      }
    ]
  },  
  {
    path: 'mobile',
    loadChildren: './pages/flat-view/flat-view.module#FlatViewModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
