import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DashboardNotesService {

  _attachModuleToggle = new BehaviorSubject<boolean>(false);
  attachModuleToggle$ = this._attachModuleToggle.asObservable();

  _moduleSnapshot: Object;

  constructor() { }

  get moduleSnapshot(): Object {
    return this._moduleSnapshot;
  }

  set moduleSnapshot(module) {
    this._moduleSnapshot = module;
  }

  clearModuleSnapshot() {
    this._moduleSnapshot = null;
  }

  toggleAttachModule(bool: boolean) {
    this._attachModuleToggle.next(bool);
  }

}
