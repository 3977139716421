import { Component, OnInit, ViewEncapsulation, Renderer2, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AccountService } from '../../../../services/account.service';
import { forkJoin, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { HeaderService } from '../../../actions/header.service';
import { ActivatedRoute, Router, Params, NavigationStart } from '@angular/router';
import { UiService } from '../../../../shared/actions/ui.service';
import { environment } from '../../../../../environments/environment.dev';
import { Workspace, Account } from '../../../../interfaces';
import { Store } from '@ngxs/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChannelsService } from '../../../../services/channels.service';
import { WorkspacesService } from '../../../../services/workspaces.service';
import { filter } from 'rxjs/operators';
import { SocialAuthService } from 'angularx-social-login';
import {SubscriptionsService} from '@services/subscriptions.service';
import { NotificationService } from '@services/notification.service';
import { DataIntegrationsService } from '@services/data-integrations.service';
import { SubscriptionModalComponent } from '../../../../shared/ui-components/subscription-modal/subscription-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {

  private TAG = 'HeaderComponent';

  account: Account;
  workspace: Workspace;
  workspaces: Workspace[];
  myLibrary: Workspace;
  isLoadingLibrary: boolean = false;
  path: string;
  subscription = new Subscription();
  userSignedIn: boolean;
  displaySidebar: boolean;
  firstName: String;
  lastName: String;
  userName: String;
  email: String;
  avatarImageUrl: String;
  showUserOptions: boolean;
  showWSDropdown: boolean;
  animateUserOptions: boolean;
  bodyClick: Function;
  displayMobileNav: boolean;
  lifetimeUser = false;
  essentialPlanId = environment.essentialPlanId;
  channelId: string;
  upgradeMessage = '';
  listenerFn: () => void;
  autoCreate = false;
  hidePrivateLinks = false;
  customer = null;
  currentRouteUrl = '';
  isLoadingBillingUrl: boolean = false;
  lastUploadedFiles: File[] = [];
  uploadPercentage = 0;

  constructor(
    private _account: AccountService,
    private _auth: SocialAuthService,
    private _ui: UiService,
    private renderer: Renderer2,
    private _headerService: HeaderService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _store: Store,
    private _deviceService: DeviceDetectorService,
    private _channelService: ChannelsService,
    private _workspaceService: WorkspacesService,
    private changeDetection: ChangeDetectorRef,
    private _subscriptionsService: SubscriptionsService,
    private _modals: NgbModal,
    private _activeModal: NgbActiveModal,
    public integrationsService: DataIntegrationsService,
    private notify: NotificationService,
  ) {


  }

  ngOnInit() {
    this.path = this._activatedRoute.snapshot.routeConfig.path;
    let route = this._activatedRoute.snapshot;
    while (route.children.length > 0) {
      route = route.children[0];
    }
    if (route.queryParams['auto-create']) {
      this.autoCreate = route.queryParams['auto-create'];
    }

    if (route.parent &&
        route.parent.data &&
        route.parent.data.page &&
        route.parent.data.page === 'shares' ) {
      this.hidePrivateLinks = true;
    }

    this.subscription.add(this._store.select(state => state.account)
      .subscribe(account => {
        this.account = account;
        this.changeDetection.detectChanges();

        forkJoin([this._subscriptionsService.getCustomer(this.account.email)])
        .subscribe(([customer]) => {
          this.customer = customer;

          if (!this.hidePrivateLinks) {
            this.validateCustomerSubscription();
          }
        });

      }));
    // this.subscription.add(this._store.select(state => state.workspace).subscribe(wsState => {
    //   if (wsState.active) {
    //     this.workspace = wsState.active;
    //   }
    // }));
    this.subscription.add(this._workspaceService.getActive$
      .pipe(
        filter(active => !!active),
        combineLatest(this._workspaceService.getWorkspaces$),
        filter(([active, workspaces]) => workspaces.length > 0)
      )
      .subscribe(([active, workspaces]) => {
        this.workspace = active;
        this.workspaces = workspaces;
        this.myLibrary = workspaces.find(({ name }) => name === 'My Library'); // Yes, this is actually how it works.
    }));
    this.subscription.add(this._channelService.getActive$
      .subscribe(channel => {
        if (!channel || !channel._id) {
          this.channelId = 'me';
        } else {
          this.channelId = channel._id;
        }
        this.changeDetection.detectChanges();
      }));

    this.subscription.add(this._ui.sidebarToggle$
      .subscribe((bool) => {
        this.displaySidebar = bool;
        this.changeDetection.detectChanges();
      }));

    this.subscription.add(this._ui._showUserOptions$
      .subscribe((bool) => {
        this.showUserOptions = bool;
        if ( this.showUserOptions ) {
          this.addBodyClickListener();
          setTimeout( () => { this.animateUserOptions = true; }, 300);
        } else {
          this.removeBodyClickListener();
          setTimeout( () => { this.animateUserOptions = false; }, 300);
        }
        this.changeDetection.detectChanges();
      }));

    this.subscription.add(this._headerService._displayMobileNavigation
      .subscribe(display => {
      this.displayMobileNav = display;
      this.changeDetection.detectChanges();
    }));

    this.subscription.add(this.integrationsService.uploadPercentage.subscribe((uploadPercentage) => {
      this.lastUploadedFiles = this.integrationsService.lastUploadedFiles;

      if (uploadPercentage >= 100) {
        if (this.myLibrary) {
          this.uploadPercentage = uploadPercentage;
          this.notify.success(
            `Uploaded`,
            'Your files have been successfully uploaded',
          );
          return;          
        }

        /**
         * This code is slightly unintuitive, so here goes.
         * When a user first signs up, they do not have a "My Library" workspace.
         * When they first perform a custom upload, the backend auto-creates this workspace for them.
         * The problem is that from the perspective of the client, we have no way of knowing *when* this happens.
         * 
         * So what we do is (1) wait for 10 seconds arbitrarily, then (2) retrieve the workspaces from the server.
         * This doesn't absolutely ensure that retrieving the workspace will succeed, but at the very least it helps mitigate the issue.
         */
        window.setTimeout(() => {
          this._workspaceService.getWorkspaces$.subscribe((workspaces) => {
            this.workspaces = workspaces;
            this.myLibrary = workspaces.find(({ name }) => name === 'My Library');

            this.uploadPercentage = uploadPercentage;
          });
          this._workspaceService.loadWorkspaces();
        }, 10000);
      } else {
        this.uploadPercentage = uploadPercentage;
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleMobileNav() {
    this.displayMobileNav = !this.displayMobileNav;
    this._headerService.toggleMobileNav(this.displayMobileNav);
  }

  goToMyLibrary() {
    if (this.myLibrary) {
      this._router.navigate([`/${this.myLibrary._id}/notebook/me/notes`]);
    }
    // this.isLoadingLibrary = true;

    // this._workspaceService.getWorkspaces$.subscribe((workspaces) => {
    //   this.workspaces = workspaces;
    //   const myLibrary = workspaces.find(({ name }) => name === 'My Library'); // Yes, this is actually how it works.
    //   if (myLibrary) {
    //     this.myLibrary = myLibrary;
    //     this._router.navigate([`/${myLibrary._id}/notebook/me/notes`]);
    //   }

    //   this.isLoadingLibrary = false;
    // });
  }

  validateCustomerSubscription() {
    const isTrialing = localStorage.getItem('isTrialing');

    this.upgradeMessage = '';
    if (this.customer && this.customer.subscription) {
      let timeLeft = 0;
      const trial_end = this.customer.subscription.trial_end;
      if (this.customer.subscription.trial_end) {
        timeLeft = trial_end * 1000 - Number(new Date());
      }

      if ((this.customer.subscription.cancel_at_period_end)) {
        this.upgradeMessage = 'Upgrade now';
      } else if (timeLeft > 0 ) {
        const days = timeLeft / (1000 * 3600 * 24);
        this.upgradeMessage = Math.round(days) + ' days left of trial';
      }
      // if there is no more time for the subscription trial
      console.log('time left for trial', timeLeft / (1000 * 3600 * 24));
      if (timeLeft <= 0) {
        if (isTrialing) {
          localStorage.removeItem('isTrialing');
          this.openSubscriptionModal();
        }
      }
    }
  }

  async openSubscriptionModal() {
    this._activeModal.close();
    const modalRef = this._modals.open(SubscriptionModalComponent, {
      centered: true,
      size: 'lg'
    });

    try {
      await modalRef.result;
    } catch (err) {
      console.error(err);
    }
  };

  noBubble(event) {
    event.stopPropagation();
  }

  toggleUserOptions(event) {
    event.stopPropagation();
    this._ui.setUserOptionsVis(!this.showUserOptions);
  }

  addBodyClickListener() {

    this.listenerFn = this.renderer.listen('window', 'click', (event) => {
      this._ui.setUserOptionsVis(false);
      // console.log('header body click');
      this.removeBodyClickListener();
    });

  }

  removeBodyClickListener() {
    if ( this.listenerFn ) {
      this.listenerFn();
    }
  }

  logOut() {
    this._ui.setUserOptionsVis(!this.showUserOptions);
    this._auth.signOut();
    this._account.logOut();
  }

  toggleWSOptions() {
    this.showWSDropdown = !this.showWSDropdown;
  }

  showIntro() {
    return this.workspace
      && this.account
      && this._deviceService.isDesktop()
      && !!this.account.showWsIntro;
  }

  launchBilling() {
    this.isLoadingBillingUrl = true;
    this._subscriptionsService.getBillingPortal()
      .subscribe(({ url }) => {
        window.location.href = url;
      }, err => {
        this.isLoadingBillingUrl = false;
      });
  }

  getFileSize(file: File): String {
    if (file && file.size > 0) {
      const size = (file.size / 1024) / 1024;
      return `${size.toFixed(3)} mb`;
    }

    return '';
  }

}
