import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

@Injectable()
export class SidebarService {

  public channels$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public tags$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public integrations$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public sort$: BehaviorSubject<any>;
  public toggle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public createChannel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    // load tags into sidebar
    // console.log('sidebar.service::constructor');
    this.sort$ = new BehaviorSubject<any>({ 'desc' : true });
  }

}
