import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { IntegrationService } from '../../actions/integration.service';
import { ModulesService } from '../../../../../services/modules.service';
import { SocketService } from '../../../../../services/socket.service';
import { ActivatedRoute } from '@angular/router';
import { WorkspacesService } from '@services/workspaces.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-module-builder',
  templateUrl: './module-builder.component.html',
  styleUrls: ['./module-builder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleBuilderComponent implements OnInit, OnDestroy {

  private TAG = 'module-builder::';

  page = 1;
  accounts: any[];
  workspaceId: string;
  integrations: any[];
  views: any[];
  properties: any[];
  profiles: any[];
  visible: boolean;
  selectedIntegration: String;
  selectedAccount: Object;
  profileId: String;
  profileName: String;
  subscription = new Subscription();

  constructor(
    private _integrations: IntegrationService,
    private _modulesService: ModulesService,
    private _socketService: SocketService,
    private _route: ActivatedRoute,
    private _workspaceService: WorkspacesService
  ) { }

  /*

    Module Builer Flow

    - ngOnInit subscribes to a list of integrations and sets this.integrations to the return value
    - module-create.component.html displays the list of this.integrations and emits an event that calls this.loadAccounts with the selected integration
    - this.loadAccounts sets this.selectedIntegration and fetches an account list. When accounts have been returned this.accounts is set to the response
    - this.page is incremented to 2 and <app-module-create-account> in the view is loaded
    - the app-module-create-account view displays the returned accounts...
    - and if the account is a google account emits loadProperties and passes the accountId
    - and if the account is facebook emits loadViews and passes the accountId
    - loadProperties sets this.properties and sets this.page to 13 so <app-module-create-account-property> is loaded in view
    - when a property is selected an event is emited which calls loadProfiles

  */

  ngOnInit() {

    this.subscription.add(this._integrations.integrationList().subscribe( (integrations) => {
      this.integrations = integrations;
    }));

    this.subscription.add(this._integrations.offCanvasToggle$.subscribe( (bool) => {
      this.visible = bool;
    }));

    this.subscription.add(this._workspaceService.getActive$
      .pipe(
        filter(workspace => !!workspace)
      )
      .subscribe(workspace => {
        this.workspaceId = workspace._id;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  gotoPage(page) {
    this.page = page;
  }

  loadAccounts(integration): void {

    const integrationId = integration.integrationId;

    this.selectedIntegration = integration.name.toUpperCase();

    if ( this.selectedIntegration === 'GOOGLE ANALYTICS' ) {
      this.selectedIntegration = 'GOOGLE';
    }

    this._integrations.accountList(integrationId).subscribe( (accounts) => {
      this.accounts = accounts;
      this.page = 2;
      // console.log('module-builder::this.accounts:', this.accounts);
    });

  }

  loadProperties(accountId: string): void {
    // console.log('loadProperties::accountId:', accountId);
    this._integrations.propertiesList(accountId).subscribe( (properties) => {
      this.properties = properties;
      // console.log('loadProperties::this.properties:', this.properties);
      this.page = 13;
    });
  }

  loadProfiles(propertyId: string): void {
    console.log('loadProfiles::propertyId:', propertyId);
    this._integrations.profileList(propertyId).subscribe((profiles) => {
      this.profiles = profiles;
      this.page = 14;
      console.log('this.profiles: ', this.profiles);
    });
  }

  loadViews(profile): void {
    const accountId = profile.accountId;
    this.profileId = profile.id;
    this.profileName = profile.name;
    this.accounts.forEach( (account) => {
      if (account.accountId === accountId) {
        this.selectedAccount = account;
      }
    });

    this._integrations.viewList(accountId).subscribe((views) => {
      console.log('views: ', views);
      this.views = views;
      this.page = 3;
    });

  }

  selectView(view: string): void {

    if ( this.selectedIntegration === 'GOOGLE ANALYTICS' ) {
      this.selectedIntegration = 'GOOGLE';
    }

    let oauth;
    let id;
    const name = view['viewId'];

    if ( this.selectedIntegration === 'FACEBOOK' ) {
      oauth = this._integrations.getOAuth();
      id = oauth['providerProfileIds'][0];
    } else {
      oauth = this._integrations.getGoogleOauth();
      id = this.profileId;
    }

    const body = {
      type: this.selectedIntegration,
      oauth,
      name,
      profile: {
        id,
        name: this.profileName
      },
      accessToken: oauth['accessToken'],
      workspaceId: this.workspaceId
    };

    console.log(this.TAG, 'metrics::update::providerProfileId::' +
    `${id}::name::${name}`);

    this._socketService.subscribeToRoom({
      component: 'metrics::update::providerProfileId::' +
        `${id}::name::${name}`
    });

    this._modulesService.createModule(body).subscribe(
      (response) => {
        this._integrations.refreshModules(true);
        this.closeIntegrations();
      },
      (error) => {
        console.log(this.TAG, `selectView createModule error: ${JSON.stringify(error, null, 2)}`);
      }
    );
  }

  closeIntegrations() {
    this._integrations.toggleIntegrations(false);
    this._integrations.dispose();
    this.page = 1;
  }

}
