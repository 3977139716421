import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Workspace } from '../../../../../interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ws-success-modal',
  templateUrl: './ws-success-modal.component.html',
  styleUrls: ['./ws-success-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSSuccessModalComponent implements OnInit {
  @Input() workspace: Workspace;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  close() {
    this.activeModal.close();
  }

}
