import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';

import { PortletComponent } from './portlet/portlet.component';
import { TabsComponent } from './tabs/tabs.component';
import { TabComponent } from './tabs/tab/tab.component';
import { ToggleComponent } from './toggle/toggle.component';
import { ModalComponent, ModalHeaderComponent, ModalContentComponent, ModalFooterComponent, ModalFooterLeftComponent } from './modal/modal.component';
import { LoaderComponent } from './loader/loader.component';
import { DragDropDirective } from '../../directives/drag-drop.directive';
@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    PortletComponent,
    TabsComponent,
    TabComponent,
    ToggleComponent,
    ModalComponent,
    ModalHeaderComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalFooterLeftComponent,
    LoaderComponent,
    DragDropDirective
  ],
  declarations: [
    PortletComponent,
    TabsComponent,
    TabComponent,
    ToggleComponent,
    ModalComponent,
    ModalHeaderComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalFooterLeftComponent,
    LoaderComponent,
    DragDropDirective
  ]
})
export class UiComponentsModule { }
