import { Workspace } from '../../interfaces';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetActiveWorkspace, LoadWorkspaces, AddWorksapceToList } from '../actions/workspace.action';

export interface WorkspaceStateModel {
  workspaces: Array<Workspace>;
  active: Workspace;
}

@State<WorkspaceStateModel>({
  name: 'workspace',
  defaults: {
    workspaces: new Array<Workspace>(),
    active: undefined
  }
})
export class WorkspaceState {
  @Selector()
  public static getWorkspaces(state: WorkspaceStateModel) {
    return state.workspaces;
  }

  @Selector()
  public static getActive(state: WorkspaceStateModel) {
    return state.active;
  }

  @Action(SetActiveWorkspace)
  setActiveWorkspace(ctx: StateContext<WorkspaceStateModel>, { payload }: SetActiveWorkspace) {
    ctx.patchState({
      active: payload
    });
  }

  @Action(LoadWorkspaces)
  loadWorkspaces(ctx: StateContext<WorkspaceStateModel>, { payload }: LoadWorkspaces) {
    ctx.patchState({
      workspaces: payload
    });
  }

  @Action(AddWorksapceToList)
  addWorkspace(ctx: StateContext<WorkspaceStateModel>, { payload }: AddWorksapceToList) {
    const {workspaces} = ctx.getState();
    workspaces.push(payload);
    ctx.patchState({
      workspaces
    });
  }
}

