import * as io from "socket.io-client";
import { Injectable, Inject } from "@angular/core";
import { SocketIOClient } from "socket.io/lib/client";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AccountService } from "./account.service";
import { environment } from "../../environments/environment";
import {
  Note,
  NoteLocking,
  Message,
  NoteComment,
  MessageSocket
} from "../interfaces";
import { NotificationService } from "./notification.service";

@Injectable()
export class SocketService {
  private TAG = "SocketService";

  socket: SocketIOClient.Socket;
  state = "";
  channel: string | null;
  private note: string | null;
  private channelRoom: string;
  private auth = false;
  queue: string;
  discussion: string;
  messages$: Observable<MessageSocket>;
  firstMessage$ = new Subject<NoteComment>();
  _refreshModules = new BehaviorSubject<boolean>(false);
  refreshModules$ = this._refreshModules.asObservable();

  constructor(
    private _accountService: AccountService,
    private _customNotification: NotificationService
  ) {
    this.connect();
  }

  public subscribeToRoom(sub): Observable<any> {
    this.socket.emit("subscribe", sub);

    return new Observable(observer => {
      this.socket.on("metrics::update", data => {
        observer.next(data);
      });
    });
  }

  unsubscribe(sub) {
    this.socket.emit("unsubscribe", { component: sub });
  }

  subscribeToChannel(channel, roomType = "channelId") {
    if (this.auth && this.channelRoom) {
      this.unsubscribe(this.channelRoom);
    }
    this.channelRoom = `notes::${roomType}::${channel}`;
    if (this.auth) {
      this.socket.emit("subscribe", { component: this.channelRoom });
    }
  }

  subscribeToNote(note) {
    if (this.note && this.auth) {
      this.unsubscribe(`notes::noteId::${this.note}`);
    }
    this.note = note;
    if (this.auth) {
      this.socket.emit("subscribe", {
        component: `notes::noteId::${this.note}`
      });
    }
  }

  onNoteCreate() {
    if (this.note && this.auth) {
      this.unsubscribe(`notes::noteId::${this.note}`);
      this.note = undefined;
    }
  }

  listenNotes(signal: string): Observable<Note | NoteLocking> {
    return new Observable(observer => {
      this.socket.on(`notes::${signal}`, data => {
        observer.next(data);
      });
      return sub => this.unsubscribe(sub);
    });
  }

  reconnectionStatus(): Observable<boolean> {
    return new Observable(observer => {
      this.socket.on("reconnect", () => {
        observer.next(true);
      });
    });
  }

  connectionStatus(): Observable<boolean> {
    return new Observable(observer => {
      this.socket.on("connect", () => {
        observer.next(true);
      });
      this.socket.on("disconnect", () => {
        observer.next(false);
      });
    });
  }

  listen(cb: Function) {
    this.socket.on("new::message", data => {
      cb({ action: "create", ...data });
    });
    this.socket.on("update::message", data => {
      cb({ action: "update", ...data });
    });
    this.socket.on("delete::message", data => {
      cb({ action: "delete", ...data });
    });
    this.socket.on("archive::message", data => {
      cb({ action: "delete", ...data });
    });
  }

  listenMessages(): Observable<MessageSocket> {
    return new Observable(obs => {
      this.socket.on("new::message", data => {
        obs.next({ action: "create", ...data });
      });
      this.socket.on("update::message", data => {
        obs.next({ action: "update", ...data });
      });
      this.socket.on("delete::message", data => {
        obs.next({ action: "delete", ...data });
      });
      this.socket.on("archive::message", data => {
        obs.next({ action: "delete", ...data });
      });
      return () => this.unsubscribeDiscussion();
    });
  }

  subscribeDiscussion(objectId: string) {
    if (this.discussion) {
      this.unsubscribe(`discussions::room::${this.discussion}`);
    }
    this.discussion = objectId;
    this.socket.emit("subscribe", {
      component: `discussions::room::${objectId}`
    });
  }

  unsubscribeDiscussion(objectId?: string) {
    this.socket.emit("unsubscribe", {
      component: `discussions::room::${objectId || this.discussion}`
    });
    this.discussion = undefined;
  }

  reconnect() {
    if (this.socket.disconnected) {
      this.socket.connect();
    }
  }

  disconnect() {
    this.socket.disconnect();
  }

  connect() {
    const { options, token } = this.getOptions();
    this.socket = io(environment.websocketUrl, options);

    this.socket.connect();

    this.socket.on("connect", () => {
      this.socket.emit("authenticate", {
        sessionToken: token
      });
    });
    this.socket.on("reconnecting", () => {
      console.log("reconnecting socket");
    });
    this.checkAuthenticated();
    this.checkMyRoom();
  }

  checkAuthenticated() {
    this.socket.on("authenticated", () => {
      console.log("socket authenticated!");
      this.auth = true;
      this.checkChannel();
      this.checkNote();
      if (this.discussion) {
        this.subscribeDiscussion(this.discussion);
      }
    });
  }

  checkChannel() {
    if (this.channelRoom) {
      this.socket.emit("subscribe", { component: this.channelRoom });
    }
  }

  checkNote() {
    if (this.note) {
      this.socket.emit("subscribe", {
        component: `notes::noteId::${this.note}`
      });
    }
  }

  checkMyRoom() {
    this.socket.on("notes::published", (data: any) => {
      this._customNotification.newNote(data);
    });
    this.socket.on("first::message", (data: NoteComment) => {
      this._customNotification.firstComment(data);
      this.firstMessage$.next(data);
    });
    this.socket.on("new::mentions", (data: NoteComment) => {
      this._customNotification.newMention(data);
    });
    this.socket.on("update::mentions", (data: NoteComment) => {
      this._customNotification.updateMention(data);
    });
  }

  getOptions() {
    const token = this._accountService.getToken();
    const options = {
      transports: ["websocket"]
    };
    return { options, token };
  }
}
