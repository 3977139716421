import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { UiService } from '../../../shared/actions/ui.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements AfterViewInit {
  windowHeight = 'auto';

  constructor(
    private location: Location,
    private _ui: UiService
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this._ui.windowHeight$.subscribe( ( windowHeight ) => {
        this.windowHeight = windowHeight + 'px';
      });
      this._ui.getWindowHeight();
    });
  }

  goBack($event) {
    $event.preventDefault();
    // window.history.back();
    this.location.back();
  }
}
