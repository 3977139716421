import { GoogleLoginProvider } from "angularx-social-login";

export const LOGIN_PROVIDERS = {
    gmail: GoogleLoginProvider.PROVIDER_ID,
    googledocs: GoogleLoginProvider.PROVIDER_ID,
    googleslides: GoogleLoginProvider.PROVIDER_ID,
    slack: null,
    googledrive: GoogleLoginProvider.PROVIDER_ID,
    googlesheets: GoogleLoginProvider.PROVIDER_ID,
    googlecalendar: GoogleLoginProvider.PROVIDER_ID,
    salesforce: null
};