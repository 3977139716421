import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { AccountService } from './account.service';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router/src/router_state';
import { RouterStateSnapshot } from '@angular/router/src/router_state';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public router: Router,
    private _accountService: AccountService,
    private _store: Store
    ) {}

  canActivate(next: ActivatedRouteSnapshot,  state: RouterStateSnapshot): Observable<boolean> {

    const token = this._accountService.getToken();
    if (!token) {
      // this.router.navigateByUrl('/sign-in');
      this.router.navigate(['/sign-in'], {queryParams: {returnUrl: state.url}});
      return of(false);
    }
    return of(true);
    // const planId = localStorage.getItem('bnotesPlanId');
    // if ( planId === environment.essentialPlanId) {
    //   this.router.navigate(['notebook']);
    //   return false;
    // }
  }
}
