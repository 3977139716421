import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationModalComponent implements OnInit {

  @Input() type: 'danger' | 'warning' | 'success';
  @Input() content: string;
  @Input() align: string;
  @Input() title: string;
  @Input() successText?: string;
  @Input() cancelText?: string;
  @Input() singleButton?: boolean;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }


}
