import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Note, Notes } from '../interfaces';
import { SearchQuery } from '../interfaces/search-query';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Params } from '@angular/router';
import { NoteState } from '../store/state';
import { Select } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class NotesService {
  Tag = 'notes.service::';

  private _updateListSource = new BehaviorSubject<boolean>(true);
  updateList$ = this._updateListSource.asObservable();

  @Select(NoteState.getNotes) getNotes$: Observable<Array<Note>>;

  @Select(NoteState.getActive) getActiveNote$: Observable<Note>;

  constructor(
    private http: HttpClient
  ) { }

  triggerListRefresh() {
    this._updateListSource.next(true);
  }
  createSearchQuery(params: Params): SearchQuery {

    const search: SearchQuery = {
      text: '',
      order: null,
      integration: '',
      tags: '',
      channels: '',
      limit: environment.queryLimit,
      workspaceId: ''
    };

    Object.keys(params).forEach((key) => {
      let queryValue = params[key].trim();

      /// normalize keys
      if (key === 'q') {
        key = 'text';
      }


      if (key === 'int') {
        key = 'integration';
      }


      if (Object.keys(search).indexOf(key) > -1) {

        if (key === 'order') {
          if (queryValue === 'asc') {
            queryValue = 1;
          }
          if (queryValue === 'desc') {
            queryValue = -1;
          }
        }

        // normalize integrations
        // add integrations as needed
        if (key === 'integration') {
          if (queryValue.includes('Google')) {
            queryValue = queryValue.replace('Google Analytics', 'GOOGLE');
          }
          if (queryValue.includes('Facebook')) {
            queryValue = queryValue.replace('Facebook', 'FACEBOOK');
          }
        }
        if (key !== 'channels' || (key === 'channels' && queryValue !== 'me')) {
          search[key] = queryValue;
        }
        if (search[key] === '' || search[key] === null) {
          delete search[key];
        }
      }
    });
    return search;
  }

  list(searchQuery: SearchQuery): Observable<Notes> {
    let httpParams = new HttpParams();
    Object.keys(searchQuery).forEach(function (key) {
      if (searchQuery[key] && searchQuery[key] !== '') {
        httpParams = httpParams.append(key, searchQuery[key]);
      }
    });


    return this.http.get<Notes>(`${environment.apiUrl}/accounts/me/notes/findby/search`, { params: httpParams })
      .pipe(
        map((n) => {

          // Sort notes
          // const sortOrder = (searchQuery['order'] !== null) ? searchQuery['order'] : -1 ;
          // n['notes'] = this.sortByDate(n['notes'], sortOrder);

          // Sort tags
          for (let i = 0; i < n['notes'].length; i++) {
            n['notes'][i].tags.sort();
          }
            return n;
          })
      );
  }

  publishToChannel(note: Note, channelId: string): Observable<Note> {
    const channels = [];
    if (channelId) {
      channels.push(channelId);
    }
    return this.http.post<Note>(`${environment.apiUrl}/accounts/me/notes/${note._id}`, { channels, edit: note.edit || false });
  }

  setEditable(note: Note, isEditable: boolean): Observable<Note> {
    return this.http.post<Note>(`${environment.apiUrl}/accounts/me/notes/${note._id}`, {  edit: isEditable || false });
  }

  fetch(noteId: string): Observable<Note> {
    return this.http.get<Note>(`${environment.apiUrl}/accounts/me/notes/${noteId}`)
      .pipe(
        map((n) => {
          /// filter out empty tags and null tags.
          /// this can be caused by bad data validation and data inconsistency
          n.tags = n.tags.filter(tag => tag !== null && tag !== '');

          // Sort tags
          n.tags.sort();

          return n;
        })
      );
  }

  put(noteId: string, note: Note): Observable<any> {
    return this.http.post<Note>(`${environment.apiUrl}/accounts/me/notes/${noteId}`, note);
  }

  destroy(noteId: string): Observable<any> {
    return this.http.post<Note>(`${environment.apiUrl}/accounts/me/notes/${noteId}`, { archive: true });

  }

  post(note: Note): Observable<any> {
    return this.http.post<Note>(`${environment.apiUrl}/accounts/me/notes`, note);
  }

  patch(noteId: string, data: any): Observable<Note> {
    return this.http.patch<Note>(`${environment.apiUrl}/accounts/me/notes/${noteId}`, data);
  }

  lockNote(body: Object): Observable<object> {
    return this.http.post<object>(`${environment.apiUrl}/accounts/me/notes/lock`, body);
  }

  unlockNote(note: string): Observable<object> {
    return this.http.post<object>(`${environment.apiUrl}/accounts/me/notes/unlock`, { note });
  }

  getYacNotes(data: Object): Observable<any> {
    return this.http.post(`${environment.apiUrl}/accounts/me/notes/yac`, data);
  }

  sortByDate(notes: Note[], order = -1) {
    notes.sort(function (a, b) {
      return +new Date(b.updatedAt) - +new Date(a.updatedAt);
    });
    if (order === 1) {
      notes.reverse();
    }
    return notes;
  }
}
