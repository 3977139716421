import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { SetActiveWorkspace } from '../store/actions/workspace.action';
import { LoadChannels, SetActiveChannel } from '../store/actions/channel.action';
import { Observable } from 'rxjs';
import { Member } from '@interfaces/index';
import { WorkspacesService } from './workspaces.service';
import { map } from 'rxjs/operators';

type wrapifyFunc = (substring: string, ...args: any[]) => string;
@Injectable()
export class UtilService {
  public static regexUrl = /((?:((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):)(?:\/\/))(?:www\.)?(?:[a-zA-Z\d-_.]+(?:(?:\.|@)[a-zA-Z\d]{2,})|localhost)(?:(?:[-a-zA-Z\d:%_+.~#!?&\/=@]*)(?:[,](?![\s]))*)*)/gmi;
  public static regexHtml = /(<([^>]+)>)/ig;

  constructor(
    private _store: Store,
    private workspacesService: WorkspacesService
  ) {}

  areEquals(arr1: Array<any>, arr2: Array<any>): boolean {
    if (!arr1 || !arr2 || arr1.length !== arr2.length) {
      return false;
    }
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  getInitials(fullName: string, limit = 2) {
    if (fullName && fullName.trim().length > 0) {
      return fullName
        .trim()
        .split(' ', limit)
        .map(str => str.charAt(0).toUpperCase())
        .join('');
    }
    return 'JD'; // as in John / Jane Doe
  }

  cloneArray(arr: Array<any>): Array<any> {
    return JSON.parse(JSON.stringify(arr));
  }

  clearActiveRedux() {
    this._store.dispatch(new SetActiveWorkspace(undefined));
    this._store.dispatch(new LoadChannels([]));
    this._store.dispatch(new SetActiveChannel({ _id: undefined, title: '', workspaceId: ''}));
  }

  getMembersInfo(members: string[]): Observable<Member[]> {
    return this.workspacesService.members$
      .pipe(
        map(list => list.filter(item => members.includes(item.account._id)))
      );
  }

  replace(content: string, searchValue: string | RegExp, replaceValue: string ): string;
  // tslint:disable-next-line:unified-signatures
  replace(content: string, searchValue: string | RegExp, replaceValue: (substring: string, ...args: any[]) => string ): string;

  replace(content: string, searchValue: string | RegExp, replaceValue: any ): string {
    return content.replace(searchValue, replaceValue);
  }

  stripHtmlTags(content: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = content;
    return this.replace(txt.value, UtilService.regexHtml, ' ');
  }
}
