import { Injectable, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject ,  Subscription ,  ReplaySubject } from 'rxjs';

@Injectable()
export class UiService {

  window: Window;
  windowHeight: number;
  _windowHeight$: ReplaySubject<number>;

  renderer: Renderer2;
  subscription: Subscription;

  listListenerFn: () => void;
  sortListenerFn: () => void;

  _sortToggle = new BehaviorSubject<boolean>(false);
  sortToggle$ = this._sortToggle.asObservable();
  sortToggled = false;

  _listToggle = new BehaviorSubject<boolean>(false);
  listToggle$ = this._listToggle.asObservable();
  listToggled = false;

  _loaderToggle = new BehaviorSubject<boolean>(true);
  loaderToggle$ = this._loaderToggle.asObservable();

  _sidebarHeight$: ReplaySubject<number>;
  _componentLoaded = new BehaviorSubject<string>('');
  _offCanvasComponentLoaded = new BehaviorSubject<string>('');
  _sidebarToggle = new BehaviorSubject<boolean>(false);
  _dimmerToggle = new BehaviorSubject<boolean>(false);
  _offCanvasToggle = new BehaviorSubject<boolean>(false);
  _userSignedIn = new BehaviorSubject<boolean>(false);
  _showUserOptions$: ReplaySubject<boolean>;

  showOverlay$ = new BehaviorSubject<boolean>(false);
  showBlur$ = new BehaviorSubject<boolean>(false);

  sidebarToggle$ = this._sidebarToggle.asObservable();
  componentLoaded$ = this._componentLoaded.asObservable();
  offCanvasComponentLoaded$ = this._offCanvasComponentLoaded.asObservable();
  offCanvasToggle$ = this._offCanvasToggle.asObservable();
  dimmerToggle$ = this._dimmerToggle.asObservable();
  userSignedIn$ = this._userSignedIn.asObservable();

  constructor(private rendererFactory: RendererFactory2) {
    this._windowHeight$ = new ReplaySubject<number>(1);
    this._sidebarHeight$ = new ReplaySubject<number>(1);
    this._showUserOptions$ = new ReplaySubject<boolean>(1);
    this.renderer = rendererFactory.createRenderer(null, null);
    window.addEventListener('resize', (event) => {
      this.getWindowHeight();
    });
  }

  get windowHeight$() {
    return this._windowHeight$.asObservable();
  }

  get signInStatus() {
    return this._userSignedIn;
  }

  get sidebarHeight$() {
    return this._sidebarHeight$.asObservable();
  }

  get showUserOptions$() {
    return this._showUserOptions$.asObservable();
  }

  getWindowHeight() {
    this.windowHeight = window.innerHeight;
    this._windowHeight$.next( this.windowHeight );
  }

  displayLoader(bool: boolean) {
    this._loaderToggle.next(bool);
  }

  toggleSort(bool: boolean) {

    this._sortToggle.next(bool);

    if ( bool ) {
      this.addWindowClickListener('sort');
    } else {
      this.removeWindowClickListener('sort');
    }

  }

  toggleList(bool: boolean) {

    this._listToggle.next(bool);

    if ( bool ) {
      this.addWindowClickListener('list');
    } else {
      this.removeWindowClickListener('list');
    }

  }

  addWindowClickListener(panel) {

    if ( panel === 'list' ) {
      this.listListenerFn = this.renderer.listen('window', 'click', (event) => {
        this.toggleList(false);
        this.removeWindowClickListener('list');
      });
    } else if ( panel === 'sort') {
      this.sortListenerFn = this.renderer.listen('window', 'click', (event) => {
        this.toggleSort(false);
        this.removeWindowClickListener('sort');
      });
    }

  }

  removeWindowClickListener(panel) {

    if ( panel === 'list' ) {
      if ( this.listListenerFn ) {
        this.listListenerFn();
      }
    } else if ( panel === 'sort' ) {
      if ( this.sortListenerFn ) {
        this.sortListenerFn();
      }
    }

  }

  getSidebarHeight() {
    this._sidebarHeight$.next( window.innerHeight );
  }

  setComponentLoaded(componentName: string) {
    this._componentLoaded.next(componentName);
    this.getWindowHeight();
  }

  setOffCanvasComponentLoaded(componentName: string) {
    this._offCanvasComponentLoaded.next(componentName);
  }

  setUserOptionsVis(bool: boolean) {
    this._showUserOptions$.next(bool);
  }

  toggleSidebar(bool: boolean) {
    console.log('uiState-toggleSidebar()::', bool);
    this._sidebarToggle.next(bool);
  }

  toggleOffCanvas(bool: boolean) {
    this._offCanvasToggle.next(bool);
  }

  toggleDimmer(bool: boolean) {
    this._dimmerToggle.next(bool);
  }

  userSignedIn(bool: boolean) {
    this._userSignedIn.next(bool);
  }

}
