import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Channel } from '../interfaces/channel';
import { LoadChannels, SetActiveChannel } from '../store/actions/channel.action';
import { ChannelState } from '../store/state/channel.state';
import { distinctUntilChanged, map } from 'rxjs/operators';


@Injectable()
export class ChannelsService {

  Tag = 'channels.service::';
  @Select(ChannelState.getActive) getActive$: Observable<Channel>;
  @Select(ChannelState.getChannels) getChannels$: Observable<Array<Channel>>;
  refresh$ = new BehaviorSubject<boolean>(true);
  constructor(
    private http: HttpClient,
    private _store: Store
    ) { }

  listFromStore(): Observable<HttpResponse<Channel[]>> {
    return this._store.select(state => state.channel)
      .pipe(
        map(state => state.channels),
        distinctUntilChanged()
      );
  }

  list(workspaceId?: string) {
    return this.http.get<Channel[]>(`${environment.apiUrl}/accounts/me/channels?workspaceId=${workspaceId}`);
  }

  create(channel: Channel) {
    return this.http.post<Channel>( `${environment.apiUrl}/accounts/me/channels`, channel);
  }

  update(channel: Channel) {
    return this.http.post<Channel>(`${environment.apiUrl}/accounts/me/channels/${channel._id}`, channel);
  }

  show(channel: Channel) {
    return this.http.get<Channel>(`${environment.apiUrl}/accounts/me/channels/${channel._id}`);
  }

  delete(channelId: String, deleteNotes: Boolean) {
    return this.http.delete(`${environment.apiUrl}/accounts/me/channels/${channelId}${deleteNotes ? '?deleteNotes=true' : ''}`);
  }

  loadChannels(workspaceId: string) {
    return this.list(workspaceId)
    .pipe(
      map(channels => {
        this._store.dispatch(new LoadChannels(channels));
        return channels;
      })
    );
  }

  setActiveChannel(channelId: string, channels: Array<Channel>) {
    const index = channels.findIndex(channel => channel._id === channelId);
    if (index > -1) {
      this._store.dispatch(new SetActiveChannel(channels[index]));
      return channels[index];
    }
    return null;
  }

  moveToWorkspace(channelId: string, workspaceId: string): Observable<Channel> {
    return this.http.put<Channel>(`${environment.apiUrl}/accounts/me/channels/${channelId}/move`, { workspaceId });
  }

  moveMultiChannel(payload: any) {
    return this.http.put(`${environment.apiUrl}/accounts/me/channels/multi/move`, payload);
  }

}
