import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Note } from '../../interfaces';
import { SetActiveNote, LoadNotes } from '../actions/note.action';


export interface NoteStateModel {
  notes: Array<Note>;
  active: Note;
}

@State<NoteStateModel>({
  name: 'note',
  defaults: {
    notes: new Array<Note>(),
    active: {
      _id: undefined,
      updatedAt: undefined,
      createdAt: undefined,
      updatedBy: undefined,
      title: undefined,
      body: undefined,
      accountId: undefined,
      snapshot: undefined,
      archive: undefined,
      tags: [],
      __v: undefined,
      'module': undefined,
      moduleId: undefined,
      'new': undefined,
      channels: [],
      edit: undefined,
      lockedBy: undefined
    }
  }
})
export class NoteState {

  @Selector()
  public static getNotes(state: NoteStateModel) {
    return state.notes;
  }

  @Selector()
  public static getActive(state: NoteStateModel) {
    return state.active;
  }

  @Action(SetActiveNote)
  setActiveNote(ctx: StateContext<NoteStateModel>, { payload }: SetActiveNote) {
    ctx.patchState({
      active: payload
    });
  }

  @Action(LoadNotes)
  loadNotes(ctx: StateContext<NoteStateModel>, { payload }: LoadNotes) {
    ctx.patchState({
      notes: payload
    });
  }
}
