import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ElementsOptions,
  StripeCardComponent,
  ElementOptions
} from "ngx-stripe";
import { Subscription } from "rxjs/Subscription";

import { Account, Card, Workspace } from "../../../../../interfaces";
import { WorkspacesService } from "../../../../../services/workspaces.service";
import { NotificationService } from "../../../../../services/notification.service";
import { MoveMultichannelModalComponent } from "../move-multichannel-modal/move-multichannel-modal.component";

@Component({
  selector: "app-ws-create-modal",
  templateUrl: "./ws-create-modal.component.html",
  styleUrls: ["./ws-create-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSCreateModalComponent implements OnInit, OnDestroy {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @ViewChild("firstModal") firstModal;
  @Input() account: Account;
  @Input() description =
    '<p class="description narrow">Keep notes contextually relevant within a company, project or organization</p>';
  cards: Card[] = [];
  proration: string;
  plans = [];
  lifetime = false;
  label = "Subscribe & Create Workspace";
  selectedCard: Card;
  billingType: "monthly" | "annually" = "monthly";
  fetching = true;
  changeCard = false;
  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "18px",
        "::placeholder": {
          color: "#CFD7E0"
        }
      }
    },
    hidePostalCode: true
  };

  features = [];

  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: "en"
  };

  cardForm: FormGroup;
  subscription: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _notify: NotificationService,
    private _workspacesService: WorkspacesService,
    private _modalService: NgbModal
  ) {}

  ngOnInit() {
    this.features = [
      "Unlimited Notes",
      "Unlimited Members",
      "Team Bundles",
      "Cloud Storage"
    ];
		this.fetching = false;
		this.label = "Create Workspace";
    this.cardForm = this._formBuilder.group({
      workspaceName: new FormControl("", [Validators.required])
    });
  }

  fetchData() {}

  validProration(total: number) {
    switch (this.billingType) {
      case "monthly":
        return total < 12;
      case "annually":
        return total < 100;
      default:
        return total < 12;
    }
  }

  createWs() {
    this._workspacesService
      .create({
        name: this.cardForm.get("workspaceName").value
      })
      .subscribe(
        workspace => {
          this.workspaceCreated(workspace);
        },
        error => {
          this.fetching = false;
          this._notify.error(
            "Error creating workspace",
            error.error ? error.error.message : error.message
          );
          this.changeDetector.detectChanges();
        },
        () => {
          this.fetching = false;
        }
      );
  }

  workspaceCreated(workspace: Workspace) {
    this._workspacesService.loadWorkspaces();

    const modalRef = this._modalService.open(MoveMultichannelModalComponent, {
      windowClass: "mt-z zoomIn animated",
      backdrop: false
    });
    modalRef.componentInstance.account = this.account;
    modalRef.componentInstance.workspace = workspace;
    this.activeModal.close();
  }

  createWorkspace() {
		this.fetching = true;
		this.createWs();
  }

  ngOnDestroy() {}
}
