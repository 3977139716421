import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  keyframes
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('loading', [
      transition('* <=> *', animate('2s', keyframes([
        style({'background-position': '-{{width}}px'})
      ])))
    ])
  ]
})
export class LoaderComponent implements OnInit {

  @Input() active = false;
  @Input() size: string;
  width: number;
  style: object;

  constructor() { }

  ngOnInit() {
    const size = this.getSize();
    this.width = (size * 16320) / 120;
    this.style = {
      height: `${size}px`,
      width: `${size}px`,
      'background-size': `${this.width}px ${size}px`
    };
  }

  getSize(): number {
    switch (this.size) {
      case 'big':
        return 120;
      case 'medium':
        return 80;
      case 'small':
        return 30;
      default:
        return 120;
    }
  }

}
