import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-portlet',
  templateUrl: './portlet.component.html',
  styleUrls: ['./portlet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortletComponent implements OnInit {

  @Input() public portletTheme;
  @Input() public portletTitle;
  @Input() public portletIcon;
  @Input() public ctaIcon;
  @Input() public ctaRoute;

  constructor() { }

  ngOnInit() {
  }

}
