import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AuthErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }

  public handleError(error: Error | HttpErrorResponse) {
    const router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
      } else {
        if (error && error.status && (error.status === 401 || error.status === 403)) {
          setTimeout(() => {
            router.navigateByUrl('/sign-in');
          }, 0);
        }
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      console.log('error handler: ', error);
    }
  }
}
