import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UiService } from '../../../shared/actions/ui.service';

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrls: ['./view-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})  
export class ViewPageComponent implements OnInit, OnDestroy {

  page: 'notebook' | 'dashboard' | 'account' | 'shares';
  subscription = new Subscription();
  expandSort: Boolean = true;

  constructor(
    private route: ActivatedRoute,
    private _uiService: UiService
  ) { }

  ngOnInit() {
    this.subscription.add(this.route.data
      .subscribe(data => {
        this.page = data.page;
      }));
    this.subscription.add(this._uiService.sortToggle$.subscribe(
      (bool: Boolean) => {
        this.expandSort = bool;
      }
    ));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
