import { Injectable } from '@angular/core';
import {
  FacebookService,
  LoginResponse,
  LoginOptions,
  AuthResponse,
  UIParams,
  UIResponse,
  InitParams,
} from 'ngx-facebook';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class FacebookServices {

  private TAG = 'FacebookServices';
  private oauthEndpoint = `${environment.apiUrl}/accounts/me/oauth`;
  private accessToken = '';
  private refreshToken = '';
  private tokenExpiresAt = '';
  private oauth = '';

  private viewList = [
    {
      viewId: 'page_fan_adds_unique',
      name: 'Page Likes'
    },
    {
      viewId: 'page_views_total',
      name: 'Page Views'
    },
    {
      viewId: 'page_impressions_unique',
      name: 'Reach Total'
    },
    {
      viewId: 'page_impressions_paid_unique',
      name: 'Reach Paid'
    },
    {
      viewId: 'page_impressions_organic_unique',
      name: 'Reach Organic'
    },

    {
      viewId: 'page_posts_impressions_unique',
      name: 'Post Impressions'
    },
    {
      viewId: 'page_posts_impressions_paid_unique',
      name: 'Post Impressions Paid'
    },
    {
      viewId: 'page_posts_impressions_organic_unique',
      name: 'Post Impressions Organic'
    },
    {
      viewId: 'page_posts_impressions_viral_unique',
      name: 'Post Impressions Viral'
    }
  ];

  private instagramViewList = [
    {
      viewId: 'impressions',
      name: 'Impressions'
    },
    {
      viewId: 'reach',
      name: 'Reach'
    },
    {
      viewId: 'follower_count',
      name: 'Follower Count'
    },
    {
      viewId: 'email_contacts',
      name: 'Email Contacts'
    },
    {
      viewId: 'phone_call_clicks',
      name: 'Phone Call Clicks'
    },
    {
      viewId: 'text_message_clicks',
      name: 'Text Message Clicks'
    },
    {
      viewId: 'get_directions_clicks',
      name: 'Get Directions Clicks'
    },
    {
      viewId: 'website_clicks',
      name: 'Website Clicks'
    },
    {
      viewId: 'profile_views',
      name: 'Profile Views'
    },
    {
      viewId: 'audience_gender_age',
      name: 'Audience Gender Age'
    },
    {
      viewId: 'audience_locale',
      name: 'Audience Locale'
    },
    {
      viewId: 'audience_country',
      name: 'Audience Country'
    },
    {
      viewId: 'audience_city',
      name: 'Audience City'
    },
    {
      viewId: 'online_followers',
      name: 'Online Followers'
    }
  ];

  constructor( private fb: FacebookService, private http: HttpClient ) {

    const initParams: InitParams = {
      appId: environment.facebookAppId,
      xfbml: true,
      version: 'v2.10'
    };

    fb.init(initParams);

  }

  getAccessToken() {
    return this.accessToken;
  }

  getOauth() {
    return this.oauth;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getTokenExpiresAt() {
    return this.tokenExpiresAt;
  }

  getViewList() {
    return this.viewList;
  }

  getInstagramViewList() {
    return this.instagramViewList;
  }

  logout(): Promise<any> {
    return this.fb.logout();
  }

  login(): Promise<any> {
    return this.fb.login();
  }

  loginWithOptions(loginOptions: LoginOptions): Promise<any> {

    return this.fb.login(loginOptions)
      .then( (response: any) => {
        this.accessToken = response.authResponse.accessToken;
        return this.getAccounts();
      });

  }

  getLoginStatus(): Promise<any> {
    return this.fb.getLoginStatus();
  }

  getProfile(): Promise<any> {
    return this.fb.api('/me');
  }

  getFriends(): Promise<any> {
    return this.fb.api('/me/friends');
  }

  getAccounts(): Promise<any> {
    return this.fb.api('/me/accounts', 'get')
      .then((response: any) => {
        const listArray = new Array();

        response.data.forEach((item) => {
          listArray.push({
            accountId: item.id,
            name: item.name,
            accessToken: item.access_token
          });
        });

        return listArray;
      });
  }

  getPage(id: string, params: any): Promise<any> {
    return this.fb.api(id, 'get', params);
  }

  share(options: UIParams): Promise<any> {
    return this.fb.ui(options);
  }

  createOAuth(type: string, code: string, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = { type: type, code: code, id: id };

      // console.log(this.TAG, body);
      this.http.post(this.oauthEndpoint, body).subscribe((response: any) => {
        // console.log(this.TAG, `createOAuth facebook response: ${JSON.stringify(response, null, 2)}`);

        if (response.oauth) {
          this.oauth = response.oauth;
          this.accessToken = response.oauth.accessToken || this.accessToken;
          this.refreshToken = response.oauth.refreshToken || '';
          this.tokenExpiresAt = response.oauth.tokenExpiresAt || '';
        }

        resolve();
      }, (error: any) => {
        reject(error);
        // console.error(this.TAG, `${JSON.stringify(error, null, 2)}`);
      });
    });
  }

  dispose() {
    this.accessToken = '';
    this.tokenExpiresAt = '';
    this.refreshToken = '';
    this.oauth = '';
  }
}
