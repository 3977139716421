import { Component, Input, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IIntegrationItem } from '@interfaces/iintegration-item';
import { DataIntegrationsService } from '@services/data-integrations.service';
import { IUserIntegration } from '@interfaces/iuser-integration';
import { IntegrationSignInResult } from '@interfaces/integration-signin-result';

@Component({
  selector: 'connect-integration-modal', 
  templateUrl: './connect-integration-modal.component.html',
  styleUrls: ['./connect-integration-modal.component.scss']
})
export class ConnectIntegrationModalComponent implements AfterViewInit {
  
  @Input() integration: IIntegrationItem;
  userIntegration: IUserIntegration = null;

  error = null;
  loading = true;
  stepTitle = 'Authenticating';

  constructor(
    private _activeModal: NgbActiveModal,
    private _dataIntegrationsService: DataIntegrationsService
  ) { }

  ngAfterViewInit (): void {
     this.connect();
  }

  handleError (error: any) {
    this.stepTitle = 'Error';
    this.error = error;
    this.loading = false;
  }

  connect (): void {
    this.loading = true;
    this.error = null;
    this.stepTitle = 'Authenticating';
    
    this._dataIntegrationsService
      .signIn(this.integration)
      .subscribe(
        (result: IntegrationSignInResult) => {
          this.stepTitle = 'Connection Established';
          this._dataIntegrationsService.connect(result)
          .subscribe(
            (userIntegration: IUserIntegration) => {
            this.userIntegration = userIntegration;
            this.stepTitle = 'Success';
            this.loading = false;
          },
          err => this.handleError(err));
        },
        err => this.handleError(err)
      );
  }

  close (): void {
    this._activeModal.close(this.userIntegration);
  }
}
