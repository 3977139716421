import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Tab } from './tab/tab.interface';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabsComponent implements OnInit {

  public tabs: Tab[] = [];

  @Input() public tabAlignment;
  @Input() public tabUnderline;
  @Input() public tabButtons;
  @Output() public selected = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.tabUnderline = ( this.tabUnderline === 'true' ) ? true : false;
    this.tabButtons = ( this.tabButtons === 'true' ) ? true : false;
  }

  public addTab(tab: Tab) {
    // console.log('adding tab: ', tab);
    if (!this.tabs.length) {
      tab.selected = true;
    }
    this.tabs.push(tab);
  }

  public selectTab($event, tab: Tab) {
    $event.preventDefault();
    this.tabs.map( (tab) => {
      tab.selected = false;
    });
    tab.selected = true;
    this.selected.emit({ selectedTab: tab });
  }

}
