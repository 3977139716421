import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserSubscription } from '../interfaces';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SubscriptionsService {

  protected _baseUrl = environment.apiUrl;
  public existsUnpaid$ = new BehaviorSubject(false);
  constructor(private http: HttpClient) { }

  getCustomer(email: string): Observable<any> {
    return this.http.get<any>(`${this._baseUrl}/accounts/me/subscriptions/customer?email=${encodeURIComponent(email)}`)
      .pipe(catchError(err => {
        console.error(err);
        throw (err.error ? err.error : err);
      }))
      .pipe(map(result => {
        if (result && result.data && result.data.length > 0) {
          const { id, name, email, phone, delinquent, default_source, subscriptions, sources } = result.data[0];
          let subscription = null;

          if (subscriptions && subscriptions.data && subscriptions.data.length > 0) {
            const { id, created, canceled_at, ended_at, current_period_end, current_period_start, days_until_due, start_date, status, trial_end, trial_start, items } = subscriptions.data[0];
            let price = null;

            if (items && items.data && items.data.length > 0) {
              price = items.data[0].price ? items.data[0].price : items.data[0].plan;
            }

            subscription = { id, created, canceled_at, ended_at, current_period_end, current_period_start, days_until_due, start_date, status, trial_end, trial_start, price };
          }

          return { id, name, email, phone, delinquent, default_source, subscription, sources };

        }
        return null;
      }));
  }

  getSubscription(): Observable<UserSubscription> {
    return this.http.get<UserSubscription>(`${this._baseUrl}/accounts/me/subscriptions`);
  }

  cancel() {
    return this.http.delete(`${this._baseUrl}/accounts/me/subscriptions`);
  }

  getBillingPortal() {
    return this.http.get<any>(`${this._baseUrl}/subscriptions/portal`)
      .pipe(catchError(err => {
        console.error(err);
        throw (err.error ? err.error : err);
      }));
  }

}
