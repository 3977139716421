export const environment = {
  production: false,
  apiUrl: "https://api-development.bundleiq.com",
  clientDomain: "app-development.bundleiq.com",
  apiAppKey: "e7caa3fe-7067-44a0-86ae-585ebfaff69d",
  sbAppId: "A07158D1-7D7C-4BA0-B004-F4952AB19A96",
  websocketUrl: "wss://sockets-development.bundleiq.com",
  tokenName: "jwt_token",
  facebookAppId: "1310205029059032",
  gacode: "UA-101061968-2",
  googleClientId:
    "152647685470-b0gc0agn5n104jmnctqbh3rhvtm12s41.apps.googleusercontent.com",
  googleApiDiscoveryDocs: [
    "https://analyticsreporting.googleapis.com/$discovery/rest?version=v4"
  ],
  googleApiScope: ["https://www.googleapis.com/auth/analytics.readonly"],
  stripeKey: "pk_test_P8Gk5YjKLBj444FV7wR26MEo",
  essentialPlanId: "plan_Cme0KzE2Ca7I5W",
  queryLimit: 24,
  toastOptions: {
    timeOut: 4000,
    position: ['top', 'right'],
    showProgressBar: false,
    theClass: 'notification-shadow bounceInRight animated',
    animate: 'fade'
  },
  paidWorkspacePlan: "test_workspaces",
  tagColors: [
    "#4F65E0",
    "#33E4E4",
    "#FC9CA6",
    "#C062D3",
    "#23C7CC",
    "#E99712",
    "#75C919",
    "#F83149",
    "#C9BC19",
    "#31BCF8",
    "#71DAEE",
    "#33EAC4",
    "#F76BA7",
    "#8362D3",
    "#23CCA4",
    "#E9DE12",
    "#19BEC9",
    "#FF0071",
    "#C99A19",
    "#3192F8"
  ],
  firebaseConfig: {
    apiKey: "AIzaSyBveTLNAU1ocTvBFy-jczEz43-EgGO2n08",
    authDomain: "bundleiq-a368b.firebaseapp.com",
    databaseURL: "https://bundleiq-a368b.firebaseio.com",
    projectId: "bundleiq-a368b",
    storageBucket: "bundleiq-a368b.appspot.com",
    messagingSenderId: "693969615085",
    appId: "1:693969615085:web:b115f093b5195d52a134e4",
    measurementId: "G-FJBTQJGBR2"
  },
  extensionId: 'olidoialnlcdepmehacnojkhanbfkmbc'
};
