import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { FacebookServices } from '../../../../services/facebook.service';
import { GoogleService } from '../../../../services/google.service';

@Injectable()
export class IntegrationService {

  public static readonly FACEBOOK_ID = 1;
  public static readonly GOOGLE_ANALYTICS_ID = 2;
  public static readonly INSTAGRAM_ID = 3;
  public static readonly QUICKBOOKS_ID = 4;
  public static readonly SHOPIFY_ID = 5;

  private TAG = 'IntegrationService';

  private selectedIntegration: string;

  _offCanvasToggle = new BehaviorSubject<boolean>(false);
  offCanvasToggle$ = this._offCanvasToggle.asObservable();

  _moduleCreated = new BehaviorSubject<boolean>(false);
  moduleCreated$ = this._moduleCreated.asObservable();

  /**
   * @Todo
   * Replace with data from database
   */
  integrationListMock = [
    {
      integrationId: IntegrationService.FACEBOOK_ID,
      name: 'Facebook',
      enabled: true,
    },
    {
      integrationId: IntegrationService.GOOGLE_ANALYTICS_ID,
      name : 'Google Analytics',
      enabled: true,
    },
    {
      integrationId: IntegrationService.INSTAGRAM_ID,
      name : 'Instagram',
      enabled: true,
    },
    {
      integrationId: IntegrationService.QUICKBOOKS_ID,
      name : 'Quickbooks',
      enabled: false,
    },
    {
      integrationId: IntegrationService.SHOPIFY_ID,
      name : 'Shopify',
      enabled: false,
    }
  ];

  constructor(
    private _facebookServices: FacebookServices,
    private _googleService: GoogleService,
  ) { }

  integrationList(): Observable<any> {
    return Observable.create( (observer) => {
      observer.next(this.integrationListMock);
      observer.complete();
    });
  }

  accountList(integrationId: number): Observable<any> {

    return Observable.create( (observer) => {

      switch (integrationId) {

        case IntegrationService.FACEBOOK_ID: {
          this.selectedIntegration = 'FACEBOOK';
          this.facebookLogin(observer);
          break;
        }

        case IntegrationService.GOOGLE_ANALYTICS_ID: {
          this.selectedIntegration = 'GOOGLE';
          this.googleLogin(observer);
          break;
        }

        case IntegrationService.INSTAGRAM_ID: {
          this.selectedIntegration = 'INSTAGRAM';
          this.facebookLogin(observer);
          break;
        }

      }

    });

  }

  facebookLogin(observer) {

    this._facebookServices.loginWithOptions({
      enable_profile_selector: true,
      scope: 'pages_show_list,read_insights'
    })
      .then( (listArray) => {
        observer.next(listArray);
        observer.complete();
      })
      .catch( (error: any) => {
        console.error(this.TAG, `facebook LoginWithOptions error: ${JSON.stringify(error, null, 2)}`);
      });

  }

  instagramLogin(observer) {
    this._facebookServices.loginWithOptions({
      enable_profile_selector: true,
      scope: 'pages_show_list,read_insights,manage_pages,instagram_basic,instagram_manage_insights'
    })
    .then( (listArray) => {
      observer.next(listArray);
      observer.complete();
    })
    .catch( (error: any) => {
      console.error(this.TAG, `instagram LoginWithOptions error: ${JSON.stringify(error, null, 2)}`);
    });
  }

  googleLogin(observer) {
    this._googleService.signIn()
    .then((listArray) => {
      observer.next(listArray);
      observer.complete();
    })
    .catch((error: any) => {
      console.error(this.TAG, `google login error: ${JSON.stringify(error, null, 2)}`);
    });
  }

  propertiesList( accountId: string ): Observable<any> {

    return Observable.create( (observer) => {

      this._googleService.filterProperties(accountId)
        .then((properties) => {
          observer.next(properties);
          observer.complete();
        })
        .catch((error: any) => {
          console.error(this.TAG, `google propertiesList error: ${JSON.stringify(error, null, 2)}`);
        });

    });

  }

  getSelectedIntegration() {
    return this.selectedIntegration;
  }

  toggleIntegrations(bool: boolean) {
    this._offCanvasToggle.next(bool);
  }

  refreshModules(bool: boolean) {
    this._moduleCreated.next(bool);
  }

  viewList(accountId: string): Observable<any> {
    console.log(this.TAG, `accountId ${accountId}`);
    return Observable.create(observer => {
      this.createOAuth(accountId, observer);
    });
  }

  profileList(propertyId: string): Observable<any> {
    return Observable.create(observer => {
      this._googleService.filterProfiles(propertyId)
        .then((profiles) => {
          observer.next(profiles);
          observer.complete();
        })
        .catch((error: any) => {
          console.error(this.TAG, `google profileList error: ${JSON.stringify(error, null, 2)}`);
        });
    });
  }

  createOAuth(accountId, observer) {
    switch (this.selectedIntegration) {
      case 'FACEBOOK': {
        this._facebookServices.createOAuth(
          this.selectedIntegration,
          this._facebookServices.getAccessToken(),
          accountId).then(() => {
            observer.next(this._facebookServices.getViewList());
            observer.complete();
          })
          .catch((error) => {
            console.error(this.TAG, `createOAuth facebook error ${JSON.stringify(error, null, 2)}`);
          });
        break;
      }
      case 'INSTAGRAM': {
        this._facebookServices.createOAuth(
          this.selectedIntegration,
          this._facebookServices.getAccessToken(),
          accountId).then(() => {
            observer.next(this._facebookServices.getInstagramViewList());
            observer.complete();
          })
          .catch((error) => {
            console.error(this.TAG, `createOAuth instagram error ${JSON.stringify(error, null, 2)}`);
          });
        break;
      }
      case 'GOOGLE': {
        this._googleService.createOAuth()
          .then(() => {
            observer.next(this._googleService.getViewList());
            observer.complete();
          })
          .catch((error) => {
            console.error(this.TAG, `createOAuth facebook error ${JSON.stringify(error, null, 2)}`);
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  getOAuth() {
    return this._facebookServices.getOauth();
  }

  getGoogleOauth() {
    return this._googleService.getOauth();
  }

  dispose() {
    this._facebookServices.dispose();
    this._googleService.dispose();
  }

}
