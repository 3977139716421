import {Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from '@services/account.service';

@Component({
  selector: 'app-yac-modal',
  templateUrl: './yac-modal.component.html',
  styleUrls: ['./yac-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YacModalComponent implements OnInit {
  @Input() code: string;

  constructor(
    private account: AccountService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  close() {
    this.activeModal.close();
  }

  connectYac() {
    this.account.connectYac({code: this.code}).subscribe((res) => {
      this.activeModal.close(res);
    });
  }
}
