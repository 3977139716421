import { Component, Input } from '@angular/core';
import { ProductPriceFiltered } from '@interfaces/product-price-filtered';
import { Card } from '@interfaces/subscription';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '@services/account.service';
import { SubscriptionsService } from '@services/subscriptions.service';
import { NotificationsService } from 'angular2-notifications';
import { forkJoin, Observable, of } from 'rxjs';

@Component({
  selector: 'subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent { 
  url: string;

  constructor(
    public _notifications: NotificationsService,
    private _account: AccountService,
    public _activeModal: NgbActiveModal,
    public _subscriptionsService: SubscriptionsService) { }

  ngOnInit() {
    this._subscriptionsService.getBillingPortal()
      .subscribe(({ url }) => {
        this.url = url;
      });
  }
}
