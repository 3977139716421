import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { take, map } from 'rxjs/operators';
import { Workspace, Member, Invite as InvObject } from '../interfaces';
import { Store, Select } from '@ngxs/store';
import { LoadWorkspaces } from '../store/actions/workspace.action';
import { WorkspaceState } from '../store/state/workspace.state';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WorkspacesService {

  Tag = 'workspaces.service::';
  members$: BehaviorSubject<Member[]> = new BehaviorSubject([]);
  invites$: BehaviorSubject<InvObject[]> = new BehaviorSubject([]);

  @Select(WorkspaceState.getWorkspaces) getWorkspaces$: Observable<Array<Workspace>>;
  @Select(WorkspaceState.getActive) getActive$: Observable<Workspace>;

  constructor(
    private http: HttpClient,
    private _store: Store
    ) { }

  list(params?: any): Observable<Workspace[]> {
    return this.http.get<Workspace[]>(`${environment.apiUrl}/workspaces`, { params });
  }

  create(workspace: WorkspaceBody, cardToken?: string): Observable<Workspace> {
    return this.http.post<Workspace>( `${environment.apiUrl}/workspaces`, {cardToken, ...workspace});
  }

  update(workspace: Workspace) {
    return this.http.patch<Workspace>(`${environment.apiUrl}/workspaces/${workspace._id}`, workspace);
  }

  show(workspace: string, params?: any): Observable<Workspace> {
    return this.http.get<Workspace>(`${environment.apiUrl}/workspaces/${workspace}`, { params });
  }

  delete(workspaceId: string) {
    return this.http.delete<Workspace>(`${environment.apiUrl}/workspaces/${workspaceId}`);
  }

  getMembers(workspaceId: string): Observable<{members: Member[], invites: InvObject[]}> {
    return this.http.get<{members: Member[], invites: InvObject[]}>(`${environment.apiUrl}/workspaces/members?workspaceId=${workspaceId}`);
  }

  addMember(member: Member) {
    return this.http.post<Member>( `${environment.apiUrl}/workspaces/members`, member);
  }

  leave(id: string) {
    return this.http.post<Member>( `${environment.apiUrl}/workspaces/${id}/leave`, {});
  }

  addMembers(members: Invite[], workspaceId: string) {
    return this.http.post<Member>( `${environment.apiUrl}/workspaces/members/multi`, {members, workspaceId});
  }

  removeMember(member: Member) {
    return this.http.delete<Member>(`${environment.apiUrl}/workspaces/members${member._id}`);
  }

  resendInvitation(invitationId: string) {
    return this.http.post<any>(`${environment.apiUrl}/invitations/resend/${invitationId}`, {});
  }

  invite(body) {
    return this.http.post<any>(`${environment.apiUrl}/invitations/send`, body);
  }

  loadWorkspaces() {
    this.list()
      .subscribe(workspaces => {
        this._store.dispatch(new LoadWorkspaces(workspaces));
      });
  }

  existsUnpaid(): Observable<boolean> {
    return this.getWorkspaces$
      .pipe(
        map(workspaces => {
          return !!workspaces.find(ws => ws.status === 'blocked');
        })
      );
  }

  transferOwnership(wsId: string, newOwner: string) {
    return this.http.patch<Workspace>(`${environment.apiUrl}/workspaces/${wsId}/transfer`, {newOwner});
  }
}

interface WorkspaceBody extends Workspace {
  oneTime?: boolean;
}
interface Invite {
  'firstName': string;
  'lastName'?: string;
  'email': string;
  'workspaceId': string;
}
