import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import base64 from 'base-64'
import { forkJoin } from 'rxjs';

import { AccountService } from '../../../services/account.service';
import { SubscriptionsService } from '@services/subscriptions.service';
import { Account } from '@interfaces/account';

@Component({
  selector: 'app-sidebar-account',
  templateUrl: './sidebar-account.component.html',
  styleUrls: ['./sidebar-account.component.scss']
})
export class SidebarAccountComponent implements OnInit {
  customer: any = null;
  account: Account;
  billingUrl: string;

  constructor(
    private _router: Router,
    private _account: AccountService,
    private _subscriptionsService: SubscriptionsService,
    private _store: Store
  ) { }

  ngOnInit() {
    this._store.select(state => state.account)
      .subscribe(account => {
        this._account = account
        forkJoin([this._subscriptionsService.getCustomer(account.email)])
        .subscribe(([customer]) => {
          this.customer = customer;
        })
      })

      this._subscriptionsService
        .getBillingPortal()
        .subscribe(data => {
          this.billingUrl = data.url;
        });
  }

  logOut() {
    this._account.logOut();
  }

}
