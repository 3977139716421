import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { WorkspacesService } from "../../../../../services/workspaces.service";
import { Workspace, Member, Invite as InvObj } from "../../../../../interfaces";
import { ModalComponent } from "../../../../../shared/ui-components/modal/modal.component";
import { NotificationService } from "../../../../../services/notification.service";
import { ChannelsService } from "@services/channels.service";

@Component({
  selector: "app-ngbd-modal-content",
  templateUrl: "./ws-invite-modal.component.html",
  styleUrls: ["./ws-invite-modal.component.scss"]
})
export class WsInviteModalComponent implements OnInit, OnDestroy {
  @Input() workspace: Workspace;
  members: Member[];
  invites: InvObj[];
  @Input() membersModal?: ModalComponent;
  myForm: FormGroup;
  subscription = new Subscription();
  inviteInputs: Array<Invite> = [];
  invitesFormArray: FormArray;
  submitValid = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _notify: NotificationService,
    private _workspacesService: WorkspacesService,
    private _channelService: ChannelsService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this._workspacesService
        .getMembers(this.workspace._id)
        .subscribe(({ members, invites }) => {
          this.invites = invites;
          this.members = members;
          this.renderForm();
        })
    );
  }

  renderForm() {
    this.myForm = this._formBuilder.group({
      invites: this._formBuilder.array(this.createInputs())
    });
    this.listenForm();
  }

  listenForm() {
    this.subscription.add(
      this.myForm.valueChanges
        .pipe(
          debounceTime(500),
          map(value =>
            value.invites.filter((val, i) => {
              const control = this.myForm.get("invites").get(i.toString());
              if (control.value.email !== "") {
                return !control.valid && control.dirty && control.touched;
              }
              return control.touched && control.dirty;
            })
          )
        )
        .subscribe(invalids => {
          this.submitValid = invalids.length === 0;
        })
    );
  }

  createInputs(): Array<FormGroup> {
    let i = 0;
    const inputs = [];
    while (i <= 4) {
      const obj = this.createItem();
      inputs.push(obj);
      i++;
    }
    return inputs;
  }

  createItem(): FormGroup {
    return this._formBuilder.group({
      firstName: "",
      lastName: "",
      email: new FormControl("", [Validators.required, Validators.email])
    });
  }

  sendInvitations() {
    if (this.submitValid) {
      const invites = this.myForm.value.invites
        .filter((invite, i) => {
          const field = this.myForm.get("invites").get(i.toString());
          if (field.value.email.trim().length > 0) {
            return field.valid && field.touched && field.dirty;
          }
          return false;
        })
        .filter(
          inv =>
            !this.members
              .map(member => member && member.account && member.account.email)
              .includes(inv.email)
        );
      if (invites.length > 0) {
        this._workspacesService
          .addMembers(invites, this.workspace._id)
          .subscribe(
            res => {
              this._channelService.refresh$.next(true);
              this.openMembersModal();
            },
            err => {
              this._notify.error(
                "Error",
                "There has been an error adding members"
              );
            }
          );
      }
    } else {
      this._notify.error(
        "Error",
        "Please correct the invalid fields before submitting"
      );
    }
  }

  openMembersModal() {
    this.activeModal.close(false);
    const modalRef = this.modalService.open(this.membersModal, {
      windowClass: "mt-z members zoomIn animated",
      backdrop: true,
      size: "lg"
    });
    modalRef.componentInstance.workspace = this.workspace;
    modalRef.result
      .then(result => {})
      .catch(error => {
        console.log(error);
      });
  }

  addItem(event): void {
    event.preventDefault();
    this.invitesFormArray = this.myForm.get("invites") as FormArray;
    this.invitesFormArray.push(this.createItem());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
interface Invite {
  firstName: string;
  lastName: string;
  email: string;
}
