import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class IntegrationsService {

  constructor() { }

  list(): Observable<any[]> {

    return Observable.create((observer) => {
      const mockIntegrations = [{
        name: 'Facebook',
        $$Active: false
      }, {
        name: 'Google Analytics',
        $$Active: false
      }];
      const list = observer.next(mockIntegrations);
      observer.complete();
    });

  }

  getMetricsName() {
    return {

      'FACEBOOK': {
        'page_fan_adds_unique': 'Page Likes',
        'page_views_total': 'Page Views',
        'page_impressions_unique': 'Reach Total',
        'page_impressions_paid_unique': 'Reach Paid',
        'page_impressions_organic_unique': 'Reach Organic',
        'page_posts_impressions_unique': 'Post Impressions',
        'page_posts_impressions_paid_unique': 'Post Impressions Paid',
        'page_posts_impressions_organic_unique': 'Post Impressions Organic',
        'page_posts_impressions_viral_unique': 'Post Impressions Viral',
        'page_cta_clicks_logged_in_total': 'Actions on Page',
        'page_video_views': 'Video Views Total',
        'page_video_views_paid': 'Video Views Paid',
        'page_video_views_organic': 'Video Views Organic',
        'page_engaged_users': 'Post Engagements'
      },

      'INSTAGRAM': {
        'impressions': 'Impressions',
        'reach': 'Reach',
        'follower_count': 'Follower Count',
        'email_contacts': 'Email Contacts',
        'phone_call_clicks': 'Phone Call Clicks',
        'text_message_clicks': 'Text Message Clicks',
        'get_directions_clicks': 'Get Directions Clicks',
        'website_clicks': 'Website Clicks',
        'profile_views': 'Profile Views',
        'audience_gender_age': 'Audience Gender Age',
        'audience_locale': 'Audience Locale',
        'audience_country': 'Audience Country',
        'audience_city': 'Audience City',
        'online_followers': 'Online Followers'
      },

      'GOOGLE': {
        'ga:users': 'All Users',
        'ga:newUsers': 'New Users',
        'ga:sessions': 'Number Of Sessions',
        'ga:sessionsPerUser': 'Sessions Per User',
        'ga:sessionDuration': 'Session Duration',
        'ga:avgSessionDuration': 'Avg. Session Duration',
        'ga:transactions': 'Transactions',
        'ga:transactionRevenue': 'Revenue',
        'ga:revenuePerTransaction': 'Avg. Order Value',
        'ga:revenuePerUser': 'Revenue per User',
        'ga:transactionRevenuePerSession': 'Per Session Value',
        'ga:bounces': 'Number Of Bounces',
        'ga:pageviews': 'Page Views',
        'ga:uniquePageviews': 'Unique Page Views',
        'ga:pageviewsPerSession': 'Pages / Session',
        'ga:timeOnPage': 'Time on Page',
        'ga:avgTimeOnPage': 'Avg. Time on Page'
      }
    };
  }

}
