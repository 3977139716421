import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoadTags, AddTag, SetUnActiveTag, SetActiveTag, SetActiveTags, ClearActiveTags } from '../actions/tag.action';

export interface Tag {
  text: string;
  color: string;
}

export interface TagStateModel {
  actives: Array<string>;
  tags: Array<Tag>;
}

@State<TagStateModel>({
  name: 'tag',
  defaults: {
    actives: new Array<string>(),
    tags: Array<Tag>()
  }
})
export class TagState {
  @Selector()
  public static getActives(state: TagStateModel) {
    return state.actives;
  }

  @Selector()
  public static list(state: TagStateModel) {
    return state.tags;
  }

  @Action(LoadTags)
  loadTags(ctx: StateContext<TagStateModel>, { payload }: LoadTags) {
    ctx.patchState({
      tags: payload
    });
  }

  @Action(AddTag)
  addTag(ctx: StateContext<TagStateModel>, { payload }: AddTag) {
    const { tags } = ctx.getState();
    tags.push(payload);
    ctx.patchState({
      tags
    });
  }

  @Action(SetActiveTag)
  setActiveTag(ctx: StateContext<TagStateModel>, { payload }: SetActiveTag) {
    const { actives } = ctx.getState();
    actives.push(payload);
    ctx.patchState({
      actives
    });
  }

  @Action(SetUnActiveTag)
  setUnActiveTag(ctx: StateContext<TagStateModel>, { payload }: SetUnActiveTag) {
    const { actives } = ctx.getState();
    const index = actives.indexOf(payload);
    if (index > -1) {
      actives.splice(index, 1);
      ctx.patchState({
        actives
      });
    }
  }

  @Action(SetActiveTags)
  setActiveTags(ctx: StateContext<TagStateModel>, { payload }: SetActiveTags) {
    ctx.patchState({
      actives: payload
    });
  }

  @Action(ClearActiveTags)
  ClearActiveTags(ctx: StateContext<TagStateModel>) {
    ctx.patchState({
      actives: new Array<string>()
    });
  }
}
