export const INTEGRATION_ICONS = {
    gmail: 'gmail.svg',
    googledocs: 'googledocs.svg',
    googleslides: 'googleslides.svg',
    slack: 'slack.svg',
    googledrive: 'googledrive.svg',
    googlesheets: 'googlesheets.svg',
    googlecalendar: 'googlecalendar.svg',
    salesforce: 'salesforce.svg',
    ebooks: 'ebooks.png',
};
