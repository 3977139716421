import { Component, Input, OnInit, ChangeDetectionStrategy, ViewChild, Query, ChangeDetectorRef } from '@angular/core';
import { WorkspacesService } from '../../../../../services/workspaces.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Account } from '../../../../../interfaces';

@Component({
  selector: 'app-resend-component',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResendComponent implements OnInit {
  @Input() id: string;
  @Input() account: Account;
  @ViewChild('domInvite') domInvite: Query;
  status: 'pending' | 'sending' | 'sent' = 'pending';
  label = 'Resend';
  done = false;

  constructor(
    private workspacesService: WorkspacesService,
    private _notify: NotificationService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  resendInvitation(id: string): void {
    if (!this.done) {
      this.status = 'sending';
      this.workspacesService.resendInvitation(id).subscribe(
        res => {
          if (res.sent) {
            this.status = 'sent';
            this.label = 'Sent';
            this.done = true;
            this._notify.success('Invitation resent', 'The email has been resent', `${this.account.firstName} ${this.account.lastName || ''}`);
          }
        },
        err => {
          this._notify.error('There was an error', 'The email was not resent, please try again later');
        },
        () => {
          this.cd.detectChanges();
        }
      );
    }
  }
}
