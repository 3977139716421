/// <reference types="chrome"/>

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UiService } from '../shared/actions/ui.service';
import { Store } from '@ngxs/store';
import { SetCurrentUser } from '../store/actions/account.action';
import { Account } from '../interfaces'

@Injectable()
export class AccountService {

  private createEndpoint =  `${environment.apiUrl}/accounts`;
  private showEndpoint = `${environment.apiUrl}/accounts/:id`;
  private verifyEndpoint = `${environment.apiUrl}/accounts/:id/verifications/:code`;
  private loginEndpoint =  `${environment.apiUrl}/accounts/auth/login`;
  private logoutEndpoint =  `${environment.apiUrl}/accounts/me/logout`;
  private forgotEndpoint = `${environment.apiUrl}/accounts/auth/forgot`;
  private resetPasswordEndpoint = `${environment.apiUrl}/accounts/me/password/reset`;
  private resendVerifyEndpoint = `${environment.apiUrl}/accounts/:id/resend`;

  private showSubscriptions = `${environment.apiUrl}/accounts/me/subscriptions`;

  constructor( private http: HttpClient, private _ui: UiService, private _router: Router, private _store: Store ) { }

  getToken(): string {
    const token = localStorage.getItem(environment.tokenName);
    return token;
  }

  getDefaultWsId() {
    return localStorage.getItem('workspaceId');
  }

  clearToken(): void {
    localStorage.removeItem(environment.tokenName);
    localStorage.removeItem('bnotesPlanId');
    localStorage.removeItem('workspaceId');
    console.log('removing: ', environment.tokenName);
  }

  addTokens(account: Account, token: string) {
    this.setJwtToken(token);
    localStorage.setItem('bnotesPlanId', account.planId);
    localStorage.setItem('workspaceId', account.workspaceId);
  }

  setJwtToken(token: string) {
    localStorage.setItem(environment.tokenName, token);
  }

  getAccount(accId?: string): Observable<any> {
    const id = (accId) ? accId : 'me';
    const endpoint = this.showEndpoint.replace(':id', id);
    return this.http.get(endpoint);
  }

  logOut() {
    this.http.post(this.logoutEndpoint, {})
      .subscribe(() => {
        this.afterLogout();
      },
      () => {
        this.afterLogout();
      });
  }

  verifyCode(id: string, code: string): Observable<any> {
    const endpoint = this.verifyEndpoint.replace(':id', id).replace(':code', code);
    return this.http.get(endpoint, { observe: 'response'});
  }

  create(account: Object): Observable<any> {
    return this.http.post(this.createEndpoint, account, { observe: 'response'});
  }

  signIn(data: Object): Observable<any> {
    return this.http.post(this.loginEndpoint, data, { observe: 'response'});
  }

  signUpYac(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/oauth/yac/signin`, {observe: 'response'});
  }

  connectYac(data: Object): Observable<any> {
    return this.http.post(`${environment.apiUrl}/oauth/yac/token`, data, {observe: 'response'});
  }

  getYacMembers(token): Observable<any> {
    return this.http.get(`${environment.apiUrl}/oauth/yac/invite?token=${token}`, {observe: 'response'});
  }

  getPublicUrl(body): Observable<any> {
    return this.http.post(`${environment.apiUrl}/oauth/yac/url`, body, {observe: 'response'});
  }

  forgotPassword(data: Object): Observable<any> {
    return this.http.post(this.forgotEndpoint, data, { observe: 'response'});
  }

  resetPassword(data: Object): Observable<any> {
    return this.http.post(this.resetPasswordEndpoint, data, { observe: 'response'});
  }

  resendVerify(id: string, data: any): Observable<Account> {
    const endpoint = this.resendVerifyEndpoint.replace(':id', id);
    return this.http.post<Account>(endpoint, data);
  }

  update(body): Observable<any> {
    return this.http.post(`${environment.apiUrl}/accounts/me`, body);
  }

  updatePassword(body): Observable<any> {
    return this.http.post(`${environment.apiUrl}/accounts/me/password`, body);
  }

  refreshAccount() {
    const token = this.getToken();
    if (!!token) {
      this.getAccount()
      .subscribe(
        (account) => {
          this._store.dispatch(new SetCurrentUser(account));
        },
        (error) => {
          localStorage.removeItem(environment.tokenName);
          // this._router.navigate(['/', 'sign-in']);
          this._router.navigate(['/sign-in']);
          // console.log('error: ', error);
        }
      );
    }
  }

  getPlanId() {
    return localStorage.getItem('bnotesPlanId');
  }

  private afterLogout(): void {
    const empty: Account = { username: undefined };
    this._store.dispatch(new SetCurrentUser(empty));
    this.clearToken();
    this._router.navigate(['/', 'sign-in']);
  }

  deleteAccount(password) {
    return this.http.post(`${environment.apiUrl}/accounts/me/delete`, { password });
  }
}
