import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { WorkspacesService } from '../services/workspaces.service';
import { Workspace, Account } from '../interfaces';
import { SetActiveWorkspace } from '../store/actions/workspace.action';
import { NotificationService } from '../services/notification.service';
import 'rxjs/add/operator/catch';

@Injectable()
export class WorkspaceResolver implements Resolve<Observable<Workspace>> {

  constructor(
      private _workspacesService: WorkspacesService,
      private _store: Store,
      private _router: Router,
      private _notify: NotificationService
    ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this._workspacesService.loadWorkspaces();
    const { params: { workspace } } = route;
    const ws$ = this._workspacesService.show(workspace);
    ws$
      .subscribe(workspace => {
        this._store.dispatch(new SetActiveWorkspace(workspace));
      });
    return ws$
      .catch(error => {
        this.handleError(error, workspace);
        return of(null);
      });
  }

  private handleError(error: any, workspaceId: string) {
    if (error.status !== 404 && error.status !== 402) {
      this._router.navigate(['/', 'sign-in']);
    } else {
      this._store.selectOnce(state => state.account)
        .pipe(
          filter(account => !!account)
        )
        .subscribe((account: Account) => {
          if (account.workspaceId !== workspaceId) {
            this._router.navigate(['/', account.workspaceId, 'notebook']);
          }
          this._notify.error('Error', error.error.message || error.message || 'The specified workspace is unavailable.');
        });
    }
  }

}
