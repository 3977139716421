import { Component, Input, forwardRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ]
})
export class ToggleComponent implements ControlValueAccessor {

  @Input() public label = 'switch';
  @Input('value') public _value: boolean;
  @Input('disableToggle') public _disableToggle: boolean;

  constructor() { }

  public onChange: any = () => { };
  public onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public writeValue(value) {
    // console.log(value);
    if (value) {
      this.value = value;
    }
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  public switch() {

    // if ( ! this._disableToggle ) {
      this.value = !this.value;
    // }

  }

}
