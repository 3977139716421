import { Component, Input, ViewChild, AfterViewInit, ChangeDetectorRef, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { fromEvent ,  Subscription } from 'rxjs';
import { Account, Workspace } from '../../../../../interfaces';
import { UiService } from '../../../../../shared/actions/ui.service';
import { WorkspacesService } from '../../../../../services/workspaces.service';
import { SetActiveWorkspace } from '../../../../../store/actions/workspace.action';
import { AccountService } from '../../../../../services/account.service';
import { SetCurrentUser } from '../../../../../store/actions/account.action';

@Component({
  selector: 'app-ws-intro',
  templateUrl: './ws-intro.component.html',
  styleUrls: ['./ws-intro.component.scss'],
})
export class WsIntroComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() account: Account;
  @Input() workspace: Workspace;

  @ViewChild('t') public popover: NgbPopover;
  @ViewChild('name') public name: ElementRef;
  subscription = new Subscription();
  first = true;
  invalid = true;

  constructor(
    private cd: ChangeDetectorRef,
    private _uiService: UiService,
    private _workspaceService: WorkspacesService,
    private _accountService: AccountService,
    private _store: Store
  ) { }

  ngOnInit() {
    this._uiService.showOverlay$.next(true);
    this.subscription.add(fromEvent(this.name.nativeElement, 'keyup')
      .subscribe(() => {
        this.invalid = this.isDisabled(this.name.nativeElement.value);
      })
    );
  }

  ngAfterViewInit() {
    this.popover.open();
    this.cd.detectChanges();
    this.subscription.add(fromEvent(this.name.nativeElement, 'keyup')
      .subscribe(() => {
        this.invalid = this.isDisabled(this.name.nativeElement.value);
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.popover.close();
    this.subscription.unsubscribe();
  }

  update(name) {
    const payload = {
      _id: this.workspace._id,
      name
    };
    this._workspaceService.update(payload)
      .subscribe((workspace) => {
        this._store.dispatch(new SetActiveWorkspace(workspace));
        this._workspaceService.loadWorkspaces();
        this.close();
      });
  }

  updateAccount() {
    this._accountService.update({ showWsIntro: false })
      .subscribe(account => {
        this._store.dispatch(new SetCurrentUser(account));
      });
  }

  cleanUp() {
    this.updateAccount();
    this._uiService.showOverlay$.next(false);
    this.subscription.unsubscribe();
  }

  onClose() {
    this.cleanUp();
  }

  close() {
    this.cleanUp();
    this.popover.close();
  }

  isDisabled(name) {
    return name.trim() === ''
      || name.trim() === this.workspace.name;
  }
}
