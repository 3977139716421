import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderService {

  public createNote: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _displayMobileNavigation = new BehaviorSubject<boolean>(false);
  displayMobileNavigation$ = this._displayMobileNavigation.asObservable();

  constructor() { }

  toggleMobileNav(bool: boolean) {
    this._displayMobileNavigation.next(bool);
  }

}
