import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChannelsService } from '@services/channels.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Account, Channel, Workspace } from '../../../../../interfaces';
import { Subscription } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { WSSuccessModalComponent } from '../ws-success-modal/ws-success-modal.component';

@Component({
  selector: 'app-move-multichannel-modal',
  templateUrl: './move-multichannel-modal.component.html',
  styleUrls: ['./move-multichannel-modal.component.scss']
})
export class MoveMultichannelModalComponent implements OnInit, OnDestroy {
  @Input() account: Account;
  @Input() workspace: Workspace;
  subscription = new Subscription();
  chForm: FormGroup;
  list: Array<Channel>;
  loading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private channelService: ChannelsService,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.subscription.add(this.channelService.getChannels$
      .pipe(
        map(channels => {
          return channels.filter(channel => channel.accountId === this.account._id && !channel.permanent);
        })
      )
      .subscribe(channels => {
        if (channels.length > 0) {
          this.list = channels;
          this.chForm = this.formBuilder.group({
            channels: this.getChannelControls(channels)
          });
        } else {
          this.moveToSuccess();
        }
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getChannelControls(channels: Array<Channel>) {
    const arr = channels.map(() => this.formBuilder.control(false));
    return this.formBuilder.array(arr);
  }

  moveChannels() {
    this.loading = true;
    const channels = this.chForm.value.channels.reduce((chs: Array<string>, current: boolean, index: number) => {
      if (current) {
        chs.push(this.list[index]._id);
      }
      return chs;
    }, new Array<string>());
    if (channels.length > 0) {
      this.channelService.moveMultiChannel({ workspaceId: this.workspace._id, channels })
      .subscribe(() => {
        this.moveToSuccess();
      },
      (error) => {
        this.notify.error('Error', error.message || error.error.message || 'There was an error');
      });
    } else {
      this.moveToSuccess();
    }
  }

  smthSelected(): boolean {
    return this.chForm.value.channels.filter((selected: boolean) => selected === true).length > 0;
  }

  moveToSuccess() {
    const modalRef = this.modalService.open(WSSuccessModalComponent, { windowClass: 'mt-z zoomIn animated', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.workspace = this.workspace;
    this.activeModal.close();
  }
}
