import { Channel } from '../../interfaces';

export class SetActiveChannel {
  static readonly type = '[Channel] SetActiveChannel';

  constructor(public payload: Channel) {}
}

export class LoadChannels {
  static readonly type = '[Channel] LoadChannels';

  constructor(public payload: Array<Channel>) {}
}
