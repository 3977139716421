import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filter, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { Workspace, Account, Member, Invite } from '@interfaces/index';
import { WsRenameModalComponent, WsMembersModalComponent, WsInviteModalComponent, WSCreateModalComponent } from '../components';
import { WorkspacesService } from '@services/workspaces.service';
import { ConfirmationModalComponent } from '@shared/ui-components/confirmation-modal/confirmation-modal.component';
import { NotificationService } from '@services/notification.service';
import { SubscriptionsService } from '@services/subscriptions.service';

@Component({
  selector: 'app-workspace-dropdown',
  templateUrl: './ws-dropdown.component.html',
  styleUrls: ['./ws-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSDropdownComponent implements OnInit, OnDestroy, AfterViewInit {

  private TAG = 'WSDropdownComponent';

  @Input() account: Account;
  @Input() path: string;
  @Input() set setWorkspace(value: Workspace) {
    this.workspace = value;
  }
  @Input() openCreate: boolean;
  subscription = new Subscription();
  workspace: Workspace;
  workspaces: Workspace[];
  members: Member[];
  invites: Invite[];
  isActive = new Array<boolean>();

  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private _workspacesService: WorkspacesService,
    private _subscriptionService: SubscriptionsService,
    private _notify: NotificationService,
    private changeDetect: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this._workspacesService.getActive$.pipe(
      filter(active => !!active),
      switchMap(active => this._workspacesService.getMembers(active._id))
    )
      .subscribe(({ members, invites }) => {
        this._workspacesService.members$.next(members);
        this._workspacesService.invites$.next(invites);
      });
    this.subscription.add(this._workspacesService.getActive$
      .pipe(
        filter(active => !!active),
        combineLatest(this._workspacesService.getWorkspaces$),
        filter(([active, workspaces]) => workspaces.length > 0)
      )
      .subscribe(([active, workspaces]) => {
        this.workspaces = workspaces.filter(ws => ws._id !== active._id);
        this.isActive = this.workspaces.map(value => {
          return !value.status || value.status === 'active';
        });
        this.changeDetect.detectChanges();
      }));
    this.subscription.add(this._workspacesService.members$
      .pipe(
        combineLatest(this._workspacesService.invites$)
      )
      .subscribe(([members, invites]) => {
        this.members = members;
        this.invites = invites;
      }));
  }

  ngAfterViewInit() {
    if (this.openCreate) {
      setTimeout(() => {
        this.openWsCreationModal();
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  leave() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {backdrop: true, centered: true});
    modalRef.componentInstance.type = 'danger';
    modalRef.componentInstance.title = 'Leave Workspace';
    modalRef.componentInstance.content = `Are you sure you would like to leave "${this.workspace.name}" Workspace? You will no longer have access to the notes stored within it unless you are invited again`;
    modalRef.componentInstance.cancelText = 'No, cancel';
    modalRef.componentInstance.successText = 'Yes, leave';
    modalRef.result.then(() => {
      this._workspacesService.leave(this.workspace._id).subscribe(res => {
        this._notify.success('Success', `You left ${this.workspace.name}`, `${this.account.firstName} ${this.account.lastName}`);
        this.router.navigate([`/${this.account.workspaceId}/notebook/me/notes/create`]);
      },
      err => {
        console.log(err);
      });
    })
    .catch(() => {

    });
  }

  open() {
    const modalRef = this.modalService.open(WsRenameModalComponent, { windowClass: 'mt-z zoomIn animated', backdrop: true });
    modalRef.componentInstance.workspace = this.workspace;
    modalRef.componentInstance.path = this.path;
  }

  openMembers() {
    const modalRef = this.modalService.open(WsMembersModalComponent, { windowClass: 'mt-z members zoomIn animated', backdrop: true, size: 'lg'});
    modalRef.componentInstance.workspace = this.workspace;
    modalRef.result.then((result) => {

    }).catch((error) => {
      console.log(error);
    });
  }

  openInvites() {
    const modalRef = this.modalService.open(WsInviteModalComponent, { windowClass: 'mt-z invite zoomIn animated', backdrop: true, size: 'lg'});
    modalRef.componentInstance.workspace = this.workspace;
    modalRef.componentInstance.membersModal = WsMembersModalComponent;
  }

  openWsCreationModal() {
    const modalRef = this.modalService.open(WSCreateModalComponent, { windowClass: 'mt-z zoomIn animated workspace', backdrop: false, size: 'lg' });
    modalRef.componentInstance.account = this.account;
  }

  onWsClick(workspace: Workspace, drop: any) {
    this.router.navigate(['/', workspace._id, this.path]);
    drop.close();
  }
}
