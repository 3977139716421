import { NgModule, ModuleWithProviders, ModuleWithComponentFactories } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleCreateAccountViewComponent } from './components/layout/module-create-account-view/module-create-account-view.component';
import { ModuleCreateAccountComponent } from './components/layout/module-create-account/module-create-account.component';
import { ModuleCreateComponent } from './components/layout/module-create/module-create.component';
import { ModuleBuilderComponent } from './components/layout/module-builder/module-builder.component';
import { IntegrationService } from './components/actions/integration.service';
import { FacebookServices } from '../../services/facebook.service';
import { ModulesService } from '../../services/modules.service';
import { GoogleService } from '../../services/google.service';
import { ModuleCreateAccountPropertyComponent } from './components/layout/module-create-account-property/module-create-account-property.component';
import { ModuleCreateAccountProfileComponent } from './components/layout/module-create-account-profile/module-create-account-profile.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    CommonModule,
    ModuleCreateAccountViewComponent,
    ModuleCreateAccountComponent,
    ModuleCreateComponent,
    ModuleBuilderComponent,
  ],
  declarations: [
    ModuleCreateAccountViewComponent,
    ModuleCreateAccountComponent,
    ModuleCreateComponent,
    ModuleBuilderComponent,
    ModuleCreateAccountPropertyComponent,
    ModuleCreateAccountProfileComponent
  ]
})
export class IntegrationsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: IntegrationsModule,
      providers: [
        IntegrationService,
        FacebookServices,
        GoogleService,
        ModulesService
      ]
    };
  }
}
