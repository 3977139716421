import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

// RxJs
import { Subscription, BehaviorSubject, Observable, combineLatest } from "rxjs";

// Services
import { SidebarService } from "../../../shared/actions/sidebar.service";
import { UiService } from "../../../shared/actions/ui.service";
import { UtilService } from "../../../services/util.service";

// Interfaces
import { Channel } from "../../../interfaces/channel";
import { ChannelsService } from "../../../services/channels.service";
import { Store } from "@ngxs/store";
import { TagsService } from "../../../services/tags.service";
import { Tag } from "../../../store/state/tag.state";
import { ChannelStateModel } from "../../../store/state/channel.state";
import { Account, Workspace } from "../../../interfaces";
import { IntegrationsService } from "../../../services/integrations.service";
import { LoadTags } from "../../../store/actions/tag.action";
import { WorkspacesService } from "../../../services/workspaces.service";
import { switchMap, filter, map } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationModalComponent } from "../../../shared/ui-components/confirmation-modal/confirmation-modal.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  Tag = "sidebar::";
  subscription = new Subscription();
  channels$: Observable<Channel[]>;
  tags$: Observable<Array<Tag>>;
  channel: Channel;
  integrations$ = new BehaviorSubject<any[]>([]);
  sort$: BehaviorSubject<any>;
  tags = new Array<string>();
  asc: boolean;
  desc: boolean;
  account: Account;
  workspace: Workspace;
  channels: Array<Channel>;
  isPaidUser: boolean;

  tagsActiveCount = 0;
  integrationsActiveCount = 0;
  private buyWSCopy =
    '<p class="description">You have reached the limit of Bundles that can be created in a FREE personal Workspace. Standby, we are building a professional Workspace for you. A professional paid Workspace allows you to create as many Bundles as you need to stay organized.</p>';

  constructor(
    private modalService: NgbModal,
    private _sidebarService: SidebarService,
    private _router: Router,
    private _ui: UiService,
    private _channelService: ChannelsService,
    private _tagService: TagsService,
    private _store: Store,
    private _utilService: UtilService,
    private _integrationsService: IntegrationsService,
    private _workspaceService: WorkspacesService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this._integrationsService.list().subscribe(integrations => {
        this.integrations$.next(integrations);
      })
    );

    this._store
      .selectOnce(state => state.account)
      .subscribe(acc => {
        this.account = acc;
        if (this.account.promoCode === "LIFETIMEUSER") {
          this.isPaidUser = true;
        } else {
          const free =
            this.account.planId == null ||
            this.account.planId === environment.essentialPlanId;
          this.isPaidUser = !free;
        }
      });

    this.subscription.add(
      combineLatest(
        this._workspaceService.getActive$,
        this._channelService.refresh$
      )
        .pipe(
          filter(([workspace]) => !!workspace),
          map(([workspace]) => {
            this.workspace = workspace;
            return workspace;
          }),
          switchMap(workspace =>
            this._channelService.loadChannels(workspace._id)
          )
        )
        .subscribe(channels => {
          this.channels = channels;
        })
    );

    this.tags$ = this._tagService.list$;
    this.channels$ = this._channelService.getChannels$;

    this.subscription.add(
      this._store
        .select(state => state.channel)
        .pipe(
          map((chState: ChannelStateModel) => {
            this.channels = chState.channels;
            return chState.active;
          }),
          filter((active: Channel) => {
            const activeId = this.getChannelId(active);
            const localId = this.channel ? this.getChannelId() : undefined;

            if (
              (localId !== activeId ||
                (this.channel &&
                  active.workspaceId !== this.channel.workspaceId)) &&
              active.title &&
              active.title.trim() !== ""
            ) {
              this.channel = active;
              return true;
            }

            return false;
          }),
          switchMap((active: Channel) =>
            this._tagService.loadTags$(this.workspace._id, active._id)
          )
        )
        .subscribe(tags => {
          this._store.dispatch(new LoadTags(tags));
        })
    );

    this.subscription.add(
      this._store
        .select(state => state.tag)
        .subscribe(tags => {
          if (!this._utilService.areEquals(this.tags, tags.actives)) {
            this.tagsActiveCount = tags.actives.length;
            this.tags = this._utilService.cloneArray(tags.actives);
          }
        })
    );

    this.sort$ = this._sidebarService.sort$;
    this.sort$.subscribe((by: any) => {
      if (by.asc) {
        this.asc = true;
        this.desc = false;
      }
      if (by.desc) {
        this.asc = false;
        this.desc = true;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  closeSidebar() {
    this._ui.toggleSort(false);
  }

  sortChange(event, order) {
    this._router.navigate(
      ["/", this.workspace._id, "notebook", this.getChannelId()],
      {
        queryParamsHandling: "merge",
        queryParams: {
          order: order
        }
      }
    );
  }

  integrationChange(integration, event) {
    const integrations = this.integrations$.getValue();
    this.integrationsActiveCount = 0;

    integrations.forEach(int => {
      if (event.target.checked === true) {
        if (int.name.toLowerCase() === integration.name.toLowerCase()) {
          int.$$Active = true;
          this.integrationsActiveCount++;
        }
      }

      if (event.target.checked === false) {
        if (int.name.toLowerCase() === integration.name.toLowerCase()) {
          int.$$Active = false;
        }
      }
    });

    const active = integrations.filter((integrationObject: any) => {
      return integrationObject.$$Active;
    });

    const integrationArray = active.map((integrationObject: any) => {
      return integrationObject.name;
    });

    this._router.navigate(
      ["/", this.workspace._id, "notebook", this.getChannelId()],
      {
        queryParamsHandling: "merge",
        queryParams: {
          int: integrationArray.join(",").replace(/(^,)|(,$)/g, "")
        }
      }
    );
  }

  tagChange(tag: Tag, event) {
    if (event.target.checked) {
      this.tags.push(tag.text);
    } else {
      const index = this.tags.indexOf(tag.text);
      if (index > -1) {
        this.tags.splice(index, 1);
      }
    }
    this.tagsActiveCount = this.tags.length;
    this.navigate();
  }

  navigate() {
    this._router.navigate(
      ["/", this.workspace._id, "notebook", this.getChannelId()],
      {
        queryParamsHandling: "merge",
        queryParams: {
          tags:
            this.tags.length > 0
              ? this.tags.join(",").replace(/(^,)|(,$)/g, "")
              : null
        }
      }
    );
  }

  createChannel() {
    if (
      !this.workspace.isPaid &&
      this.account._id !== this.workspace.accountId
    ) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, {
        windowClass: "mt-z confirmation",
        backdrop: false
      });
      modalRef.componentInstance.type = "warning";
      modalRef.componentInstance.content = `<i class="locked"></i><h4>You don\’t have permission</h4><span>Only the owner of this workspace can create new bundles</span>`;
      modalRef.componentInstance.title = "Create Bundle";
      modalRef.componentInstance.successText = "Ok, Thanks";
      modalRef.componentInstance.align = "center";
      modalRef.componentInstance.singleButton = true;
    } else if (
      !this.workspace.channelLimit ||
      this.channels.length < this.workspace.channelLimit
    ) {
      this._sidebarService.createChannel$.next(true);
    }
  }

  clearIntegrationFilter() {
    const integrations = this.integrations$.getValue();
    this.integrationsActiveCount = 0;

    for (const int of integrations) {
      int.$$Active = false;
    }

    this._router.navigate(
      ["/", this.workspace._id, "notebook", this.getChannelId()],
      {
        queryParamsHandling: "merge",
        queryParams: {
          int: null
        }
      }
    );
  }

  clearTagsFilter() {
    this.tags = new Array<string>();
    this.tagsActiveCount = 0;
    this.navigate();
  }

  getChannelId(channel?: Channel) {
    return channel ? channel._id || "me" : this.channel._id || "me";
  }

  isChecked(tag: string): boolean {
    return this.tags.indexOf(tag) > -1;
  }
}
