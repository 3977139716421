import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-module-create-account',
  templateUrl: './module-create-account.component.html',
  styleUrls: ['./module-create-account.component.scss']
})
export class ModuleCreateAccountComponent implements OnInit {

  private TAG = 'ModuleCreateAccountComponent';

  @Input() accounts: any;
  @Output() loadViews = new EventEmitter();
  @Output() loadProperties = new EventEmitter();
  @Output() gotoPage = new EventEmitter();

  constructor() { }

  loadAccountView(account) {
    if (
      account.hasOwnProperty('value') &&
      account.value.selfLink.includes('google')
    ) {
      this.loadProperties.emit(account.accountId);
    } else {
      this.loadViews.emit(account);
    }
  }

  navigate(page) {
    this.gotoPage.emit(page);
  }

  ngOnInit() {

  }

}
