import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TabsComponent } from '../tabs.component';
import { Tab } from './tab.interface';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabComponent implements OnInit, Tab {

  @Input() public tabTitle;
  @Input() public selected;

  constructor( private tabsComponent: TabsComponent ) { }

  ngOnInit() {
    // console.log('tab init:', this);
    this.tabsComponent.addTab(this);
  }

}
