import { Workspace } from '../../interfaces';

export class SetActiveWorkspace {
  static readonly type = '[Workspace] SetActiveWorkspace';

  constructor(public payload: Workspace) {}
}

export class LoadWorkspaces {
  static readonly type = '[Workspace] LoadWorkspaces';

  constructor(public payload: Array<Workspace>) { }
}

export class AddWorksapceToList {
  static readonly type = '[Workspace] AddWorkspaceToList';

  constructor(public payload: Workspace) {}
}
