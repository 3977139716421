import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-module-create',
  templateUrl: './module-create.component.html',
  styleUrls: ['./module-create.component.scss']
})
export class ModuleCreateComponent implements OnInit {

  @Input() integrations: any;
  @Output() loadAccounts = new EventEmitter();
  @Output() gotoPage = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  navigate(page) {
    this.gotoPage.emit(page);
  }

  loadIntegrationAccounts(integration) {

    if ( !integration.enabled ) {
      return;
    }

    this.loadAccounts.emit(integration);

  }

}
