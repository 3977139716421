import { TagStateModel, Tag } from '../state/tag.state';

export class LoadTags {
  static readonly type = '[Tag] LoadTags';

  constructor(public payload: Array<Tag>) {}
}

export class AddTag {
  static readonly type = '[Tag] AddTag';

  constructor(public payload: Tag) {}
}

export class SetActiveTag {
  static readonly type = '[Tag] SetActiveTag';

  constructor(public payload: string) {}
}

export class SetUnActiveTag {
  static readonly type = '[Tag] SetUnActiveTag';

  constructor(public payload: string) {}
}

export class SetActiveTags {
  static readonly type = '[Tag] SetActiveTags';

  constructor(public payload: Array<string>) {}
}

export class ClearActiveTags {
  static readonly type = '[Tag] ClearActiveTags';

  constructor() {}
}
