import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import test from './test/module';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ClientService {

  private _baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getClients(params?: any): Observable<any> {
    return this.http.get(`${this._baseUrl}/accounts/me/clients`, {params: params});
  }

  getClientById(id: string, params: any): Observable<Object> {
   return this.http.get(`${this._baseUrl}/accounts/me/clients/${id}`, {params: params});
  }

  createClient(body: any): Observable<Object> {
    return this.http.post(`${this._baseUrl}/accounts/me/clients`, body);
  }

  updateClientById(id: string, body: any): Observable<Object> {
    return this.http.put(`${this._baseUrl}/accounts/me/clients/${id}`, body);
  }

  removeClientById(id: string): Observable<Object> {
    return this.http.delete(`${this._baseUrl}/accounts/me/clients/${id}`);
  }

  checkClientIsValid(body: any): Observable<Object> {
    return this.http.post(`${this._baseUrl}/oauth/authorize`, body);
  }

  signIn(body: any): Observable<Object> {
    return this.http.post(`${this._baseUrl}/oauth/signin`, body);
  }
}
