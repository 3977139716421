import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, combineLatest } from "rxjs/operators";
import { WorkspacesService } from "../../../../../services/workspaces.service";
import {
  Workspace,
  Member,
  Invite as InvObj,
  Account
} from "../../../../../interfaces";
import { Store } from "@ngxs/store";
import { SetActiveWorkspace } from "../../../../../store/actions/workspace.action";
import { WsInviteModalComponent } from "../ws-invite-modal/ws-invite-modal.component";
import { environment } from "../../../../../../environments/environment";
import { ConfirmationModalComponent } from "@shared/ui-components/confirmation-modal/confirmation-modal.component";
import { NotificationService } from "@services/notification.service";

@Component({
  selector: "app-ngbd-modal-content",
  templateUrl: "./ws-members-modal.component.html",
  styleUrls: ["./ws-members-modal.component.scss"]
})
export class WsMembersModalComponent implements OnInit, OnDestroy {
  @Input() workspace: Workspace;
  invites: InvObj[];
  members: Member[];
  loading: boolean;
  success: boolean;

  @ViewChildren("domInvite") domInvites: QueryList<any>;
  collapse: {
    [key: string]: boolean;
  };
  myForm: FormGroup;
  subscription = new Subscription();
  val = "";
  account: Account;

  constructor(
    public activeModal: NgbActiveModal,
    private _workspaceService: WorkspacesService,
    private _store: Store,
    private modalService: NgbModal,
    private _notify: NotificationService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.success = false;
    this._workspaceService.members$.next([]);
    this._workspaceService.invites$.next([]);
    this._store
      .selectOnce(state => state.account)
      .subscribe((account: Account) => {
        this.account = account;
      });
    this.myForm = new FormGroup({
      searchMembers: new FormControl()
    });
    this._workspaceService
      .getMembers(this.workspace._id)
      .subscribe(({ members, invites }) => {
        this._workspaceService.members$.next(members);
        this._workspaceService.invites$.next(invites);
      });
    this.subscription.add(
      this.myForm.controls.searchMembers.valueChanges
        .pipe(debounceTime(500))
        .subscribe(val => {
          this.val = val;
        })
    );
    this.subscription.add(
      this._workspaceService.members$
        .pipe(combineLatest(this._workspaceService.invites$))
        .subscribe(([members, invites]) => {
          this.members = members;
          this.invites = invites;
          this.loading = false;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getMembers() {
    return this.members.filter(this.filterMembers(this.val));
  }

  transferOwnership(account: Account) {
    const ref = this.modalService.open(ConfirmationModalComponent, {
      backdrop: true
    });
    ref.componentInstance.type = "danger";
    ref.componentInstance.content =
      "After this, the user you chose will be the owner of every note and channel that belongs to you in this workspace";
    ref.componentInstance.align = "center";
    ref.componentInstance.title = "Are you sure?";
    ref.componentInstance.successText = "Yes, transfer";
    ref.componentInstance.cancelText = "No, remain as owner";
    ref.result
      .then(() => {
        this.loading = true;
        this._workspaceService
          .transferOwnership(this.workspace._id, account._id)
          .subscribe(
            (ws: Workspace) => {
              this._store.dispatch(new SetActiveWorkspace(ws));
              this._workspaceService.loadWorkspaces();
              this.loading = false;
              this.success = true;
            },
            err => {
              this.loading = false;
              this._notify.error(
                "Error",
                err.error ? err.error.message : err.message
              );
            }
          );
      })
      .catch(() => {
        this.loading = false;
      });
  }

  submitForm() {
    const payload = {
      _id: this.workspace._id,
      name: this.myForm.value.workspaceName
    };
    this.subscription.add(
      this._workspaceService.update(payload).subscribe(workspace => {
        this._store.dispatch(new SetActiveWorkspace(workspace));
        this._workspaceService.loadWorkspaces();
        this.activeModal.close(true);
      })
    );
  }

  filterMembers(val: string) {
    return (member: Member) => {
      return member.account.firstName
        ? member.account.firstName.toLowerCase().includes(val.toLowerCase())
        : false || member.account.lastName
        ? member.account.lastName.toLowerCase().includes(val.toLowerCase())
        : false || member.account.email
        ? member.account.email.toLowerCase().includes(val.toLowerCase())
        : false;
    };
  }

  getInitials(name: string, limit: number = -1): string {
    return name
      .split(" ", limit)
      .map(n => n[0])
      .join("");
  }

  getColor(index): string {
    return environment.tagColors[index];
  }

  openInvite() {
    this.activeModal.close(false);
    const modalRef = this.modalService.open(WsInviteModalComponent, {
      windowClass: "mt-z invite zoomIn animated",
      backdrop: true,
      size: "lg"
    });
    modalRef.componentInstance.workspace = this.workspace;
    modalRef.componentInstance.members = this.members;
    modalRef.componentInstance.invites = this.invites;
    modalRef.componentInstance.membersModal = WsMembersModalComponent;
    modalRef.result
      .then(result => {})
      .catch(error => {
        console.log(error);
      });
  }
}
