import { NgModule } from '@angular/core';
import { EmptyComponent } from './ui-components/empty/empty.component';



@NgModule({
  imports: [],
  exports: [
    EmptyComponent
  ],
  declarations: [
    EmptyComponent
  ]
})
export class EmptyModule { }
