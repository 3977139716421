import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiUrl)) {
      const token = localStorage.getItem(environment.tokenName);

      const requestObject = {
        setHeaders: {
          // Accept: 'application/json',
          'Accept-Version': '<=2.3.0',
          'api-app-key': `${environment.apiAppKey}`
        }
      };

      if (token) {
        requestObject.setHeaders['Authorization'] = `Bearer ${token}`;
      }

      request = request.clone(requestObject);
    }
    return next.handle(request);
  }
}

