import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from '../../../../../services/util.service';

@Component({
  selector: 'app-ws-initials-component',
  templateUrl: './ws-initials.component.html',
  styleUrls: ['./ws-initials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WSInitialsComponent implements OnInit {

  subscription = new Subscription();
  @Input() name: string;
  @Input() color: string;
  @Input() circle = false;

  constructor(
    private _utilService: UtilService
  ) { }

  ngOnInit() {
  }

  getInitials(name: string): string {
    return this._utilService.getInitials(name);
  }
}
