import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IIntegrationItem } from '@interfaces/iintegration-item';
import { DataIntegrationsService } from '@services/data-integrations.service';

@Component({
  selector: 'disconnect-integration-modal', 
  templateUrl: './disconnect-integration-modal.component.html',
  styleUrls: ['./disconnect-integration-modal.component.scss']
})
export class DisconnectIntegrationModalComponent {
  
  @Input() integration: IIntegrationItem;

  error = null;
  loading = false;

  constructor(
    private _activeModal: NgbActiveModal,
    private _dataIntegrationsService: DataIntegrationsService
  ) { }


  disconnect (): void {
    this.loading = true;
    this.error = null;
    this._dataIntegrationsService
      .disconnect(this.integration.userIntegrationId)
      .subscribe( _ => {
        this.close(true)
      }, 
      err => {
        this.error = err;
        this.loading = false;
      });
  }

  close (success: boolean = false): void {
    this._activeModal.close(success);
  }
}
