import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-module-create-account-profile',
  templateUrl: './module-create-account-profile.component.html',
  styleUrls: ['./module-create-account-profile.component.scss']
})
export class ModuleCreateAccountProfileComponent implements OnInit {

  @Input() profiles: any;
  @Output() loadViews = new EventEmitter();
  @Output() gotoPage = new EventEmitter();

  constructor() { }

  selectAccountProfile(profile): void {
    this.loadViews.emit(profile);
  }

  navigate(page: number): void {
    this.gotoPage.emit(page);
  }

  ngOnInit() {
  }

}
