import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../../../components/actions/header.service';
import { AccountService } from '../../../../services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { WorkspacesService } from '../../../../services/workspaces.service';
import { ChannelsService } from '../../../../services/channels.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit, OnDestroy {

  displayNav: boolean;
  workspaceId: string;
  channelId: string;
  subscription = new Subscription();

  constructor(
    private _headerService: HeaderService,
    private _account: AccountService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _workspaceService: WorkspacesService,
    private _channelService: ChannelsService
  ) { }

  ngOnInit() {
    this.subscription.add(this._headerService._displayMobileNavigation.subscribe( (display) => {
      this.displayNav = display;
    }));
    this.subscription.add(this._workspaceService.getActive$
      .pipe(
        filter(workspace => !!workspace)
      )
      .subscribe(workspace => {
        this.workspaceId = workspace._id;
    }));
    this.subscription.add(this._channelService.getActive$
      .subscribe(channel => {
        if (!channel || !channel._id) {
          this.channelId = 'me';
        } else {
          this.channelId = channel._id;
        }
      }));

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleNav() {
    this.displayNav = !this.displayNav;
    this._headerService.toggleMobileNav(this.displayNav);
  }

  logOut() {
    this.toggleNav();
    this._account.logOut();
  }

  createNote() {
    this._router.navigate([this.workspaceId, 'notebook', this.channelId, 'notes', 'create']);
  }

}
