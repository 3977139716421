import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { WorkspacesService } from '../../../../../services/workspaces.service';
import { Workspace, Account } from '../../../../../interfaces';
import { Store } from '@ngxs/store';
import { SetActiveWorkspace } from '../../../../../store/actions/workspace.action';
import { NotificationService } from '../../../../../services/notification.service';
import { ConfirmationModalComponent } from '../../../../../shared/ui-components/confirmation-modal/confirmation-modal.component';
import { UiService } from '../../../../../shared/actions/ui.service';

@Component({
  selector: 'app-ngbd-modal-content',
  templateUrl: './ws-rename-modal.component.html',
  styleUrls: ['./ws-rename-modal.component.scss']
})
export class WsRenameModalComponent implements OnInit, OnDestroy {
  @Input() workspace: Workspace;
  @Input() path: string;
  defaultId: string;
  myForm: FormGroup;
  subscription = new Subscription();
  account: Account;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _workspaceService: WorkspacesService,
    private _store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private _notify: NotificationService,
    private ui: UiService
    ) {}

  ngOnInit() {
    this.myForm = new FormGroup({
      'workspaceName': new FormControl(this.workspace.name, [Validators.required])
    });
    this._store.select(state => state.account)
      .subscribe(account => {
        this.account = account;
      });
  }

  ngOnDestroy() {
    this.ui.showBlur$.next(false);
    this.subscription.unsubscribe();
  }

  submitForm() {
    const payload = {
      _id: this.workspace._id,
      name: this.myForm.value.workspaceName
    };
    this.subscription.add(this._workspaceService.update(payload)
    .subscribe((workspace) => {
      this._store.dispatch(new SetActiveWorkspace(workspace));
      this._workspaceService.loadWorkspaces();
      this.activeModal.close(true);
    }, (error) => {
      this._notify.error(error.error.code || error.code, error.error.message || error.message);
    }));
  }

  deleteWorkspace(id: string) {
    if (id !== this.account.workspaceId) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'mt-z confirmation', backdrop: false, size: 'sm'});
      modalRef.componentInstance.type = 'danger';
      modalRef.componentInstance.content = 'After your workspace is deleted, it will be impossible to recover your data';
      modalRef.componentInstance.title = 'Are you sure?';
      modalRef.componentInstance.align = 'center';
      modalRef.result
        .then(() => {
          this.subscription.add(this._workspaceService.delete(id).subscribe(() => {
            this.activeModal.close();
            this.router.navigate(['/', this.account.workspaceId, this.path]);
            const name = `${this.account.firstName} ${this.account.lastName}`;
            this._notify.success('Success', `"${this.workspace.name}" deleted`, name);
          }));
        })
        .catch(() => {
          this.ui.showBlur$.next(true);
        });
    } else {
      this._notify.error('Error', 'Default workspace can\'t be deleted');
    }
  }
}
