import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingModalComponent implements OnInit {

  @Input() type: 'danger' | 'warning' | 'success';
  @Input() content: string;
  @Input() align: string;
  @Input() title: string;
  @Input() successText?: string;
  @Input() cancelText?: string;
  @Input() singleButton?: boolean;
  @Input() installed: boolean;
  @Input() stepText: string;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigateToFileUpload() {
    this.router.navigateByUrl(`${this.router.url}`, { state: { abc: 123 } });
    this.activeModal.close('file_upload');
  }

  navigateToChromeExtension() {
    if(!this.installed) {
      window.open('https://chrome.google.com/webstore/detail/bundleiq/olidoialnlcdepmehacnojkhanbfkmbc?hl=en');
    }
    this.remove();
  }

  connect(source) {
    return source;
  }

  async remove() {
    await localStorage.removeItem('newCustomer');
    this.activeModal.dismiss();
  }

}
