import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Account } from '../interfaces';
import { Store } from '@ngxs/store';
import { take, filter } from 'rxjs/operators';

@Injectable()
export class AccountResolver implements Resolve<Observable<Account> | Account> {

  constructor(
    private _store: Store
    ) {}

  resolve(): Observable<Account> | Account {
    return this._store
      .selectOnce(state => state.account)
      .pipe(
        filter(account => !!account._id),
        take(1)
      );
  }

}
