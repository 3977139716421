import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Share, ShareInfo, ShareResponse } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class SharesService {
  Tag = 'shares.service::';

  constructor(
    private http: HttpClient
  ) { }

  findSharesByNote(noteId: string): Observable<[Share]> {
    return this.http.get<[Share]>(`${environment.apiUrl}/accounts/me/notes/shares/note/${noteId}`);
  }

  findShare(id: string): Observable<ShareResponse> {
    return this.http.get<ShareResponse>(`${environment.apiUrl}/shares/${id}`);
  }

  createShare(share: ShareInfo): Observable<Share> {
    return this.http.post<Share>(`${environment.apiUrl}/accounts/me/notes/shares`, share);
  }

  deleteShare(shareId: string): Observable<object> {
    return this.http.delete<object>(`${environment.apiUrl}/accounts/me/notes/shares/${shareId}`);
  }
}
