

import { take, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Store, Select } from '@ngxs/store';

import {environment} from '../../environments/environment';
import { LoadTags } from '../store/actions/tag.action';
import { Tag, TagState } from '../store/state/tag.state';

@Injectable()
export class TagsService {
  @Select(TagState.getActives) getActives$: Observable<Array<string>>;
  @Select(TagState.list) list$: Observable<Array<Tag>>;

  constructor(
    private http: HttpClient,
    private _store: Store
    ) {

  }

  list(workspaceId: string, channelId?: string): Observable<Array<Tag>> {
    const params: any = {
      workspaceId
    };
    if (channelId) {
      params.channelId = channelId;
    }
    return this.http.get<Array<Object>>(`${environment.apiUrl}/accounts/me/notes/findby/tags`, { params })
      .pipe(
        map(res => {
          const sortedKeys = Object.keys(res).sort((a, b) => a.localeCompare(b));
          const newArr = new Array<Tag>();
          for (let i = 0; i < sortedKeys.length; i++) {
            const tag: Tag = { text: sortedKeys[i], color: res[sortedKeys[i]]};
            newArr.push(tag);
          }
          return newArr;
      })
      );
  }

  loadTags(workspaceId: string, channelId?: string) {
    this.loadTags$(workspaceId, channelId)
      .subscribe(tags => {
        this._store.dispatch(new LoadTags(tags));
      }
    );
  }

  loadTags$(workspaceId: string, channelId?: string) {
    return this.list(workspaceId, channelId)
      .pipe(
        take(1)
      );
  }
}
