import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { SidebarService } from '../../../../../shared/actions/sidebar.service';
import { NotesService } from '../../../../../services/notes.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Note, Channel } from '../../../../../interfaces';


@Component({
  selector: 'app-publish-note-modal',
  templateUrl: './publish-note-modal.component.html',
  styleUrls: ['./publish-note-modal.component.scss']
})
export class PublishNoteModalComponent implements OnInit {
  publishToChannelForm: FormGroup;
  channelsSearchResults = [];
  searchChannelsControl = new FormControl();
  @Input() note: Note;
  @Input() channels: Array<Channel>;

  constructor(
    private activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private _sidebarService: SidebarService,
    private _noteService: NotesService,
    private _notify: NotificationService
  ) { }

  ngOnInit() {
    this.publishToChannelForm = this._formBuilder.group({
      search: '',
      channel: ''
    });
    this.searchChannelsControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((searchQuery) => {
        this.searchChannelsQuery(searchQuery);
      });
    this.channelsSearchResults = this.channels;
    this.searchChannelsControl.setValue('');
  }

  searchChannelsQuery(searchQuery: string) {
    if (searchQuery.length === 0) {
      this.channelsSearchResults = this.channels;
      return;
    }
    const searchResults = this.channels.filter((channel, index) => {
      if (index === 0) { return false; }
      if (channel.title.toLowerCase().startsWith(searchQuery.toLowerCase())) {
        return channel;
      }
      return false;
    });
    this.channelsSearchResults = searchResults;
  }

  createNewChannel() {
    this.activeModal.dismiss(false);
    this._sidebarService.createChannel$.next(true);
  }

  onClose() {
    this.activeModal.dismiss(false);
  }

  publishToChannel() {
    const channelId = this.publishToChannelForm.value.channel;
    this._noteService.publishToChannel(this.note, channelId).subscribe((note) => {
      const { channels } = note;
      this.activeModal.close(channels[0]._id);
    }, (error) => {
      this._notify.error(
        'Error',
        'We weren\'t able to publish your note to the selected channel.'
      );
    });
  }

  checkPublishValid() {
    if (this.publishToChannelForm.value && this.publishToChannelForm.value.channel) {
      this.publishToChannel();
    } else {
      this._notify.error(
        `Error`,
        'You need to specify a channel in order to publish the note on it.'
      );
    }
  }

  isChecked(channelId: string) {
    return this.note.channels
      && this.note.channels.length > 0
      && this.note.channels[0]._id === channelId;
  }

}
