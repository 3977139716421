import { Component, OnInit, Input } from '@angular/core';
import { Workspace } from '../../../../../../../interfaces';
import { ChannelsService } from '../../../../../../../services/channels.service';
import { Store } from '@ngxs/store';
import { WorkspaceStateModel } from '../../../../../../../store/state/workspace.state';
import { UtilService } from '../../../../../../../services/util.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../../../../services/notification.service';


@Component({
  selector: 'app-move-channel-modal',
  templateUrl: './move-channel-modal.component.html',
  styleUrls: ['./move-channel-modal.component.scss'],
})
export class MoveChannelModalComponent implements OnInit {
  @Input() channelId: string;
  wsActive: Workspace;
  list = new Array<Workspace>();
  workspaceForm: FormGroup;
  loading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private channelService: ChannelsService,
    private util: UtilService,
    private store: Store,
    private formBuilder: FormBuilder,
    private router: Router,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.workspaceForm = this.formBuilder.group({
      workspace: new FormControl(null, Validators.required)
    });
    this.store.selectOnce(state => state.workspace)
      .subscribe((state: WorkspaceStateModel) => {
        this.wsActive = state.active;
        const list: Array<Workspace> = this.util.cloneArray(state.workspaces);
        this.list = list.filter(
          elem => elem._id !== this.wsActive._id &&
          (elem.status === undefined || elem.status === 'active') &&
          elem.isPaid === true
        );
      });
  }

  moveWorkspace() {
    this.loading = true;
    const workspaceId = this.workspaceForm.get('workspace').value;
    this.channelService.moveToWorkspace(this.channelId, workspaceId)
      .subscribe(channel => {
        this.activeModal.close();
        this.router.navigate(['/', channel.workspaceId, 'notebook', channel._id ]);
      },
      error => {
        const message = error.message
          ? error.message
          : 'There was a problem trying to move this channel';
          this.notify.error('Error', message);
          this.goBack();
      });
  }

  goBack() {
    this.activeModal.dismiss();
  }
}
