import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { UiService } from './actions/ui.service';
import { DashboardNotesService } from '../services/dashboard-notes.service';
import { SidebarService } from './actions/sidebar.service';
import { SocketService } from '../services/socket.service';
import { AccountService } from '../services/account.service';
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';
import { WorkspacesService } from '../services/workspaces.service';

@NgModule({
  imports: [
    CommonModule,
    UiComponentsModule,
  ],
  exports: [ UiComponentsModule ],
  declarations: []
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        UiService,
        DashboardNotesService,
        SidebarService,
        SocketService,
        WorkspacesService,
        NotificationService,
        AccountService, {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        }
       ]
    };
  }
}
