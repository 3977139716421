import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountService } from '../../../../services/account.service';
import { NotificationService } from '../../../../services/notification.service';
import { ClientService } from '@services/client.service';

@Component({
  selector: 'app-setting-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmSettingDeleteModalComponent implements OnInit {
  myForm: FormGroup;
  @Input() public clientIndex;

  constructor(
    public activeModal: NgbActiveModal,
    private _client: ClientService,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.myForm = new FormGroup({
      'password': new FormControl('', [Validators.required])
    });
  }

  submit() {
    const password = this.myForm.get('password').value;
    this._client.removeClientById(this.clientIndex).subscribe(
      () => {
        this.activeModal.close();
      },
      err => {
        console.log(err)
        this.notify.error('Error', err.error ? err.error.message : err.message);
      });

  }

}
