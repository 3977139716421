import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { forkJoin, from, Observable, BehaviorSubject, throwError } from 'rxjs';
import { IIntegration } from '@interfaces/iintegration';
import { catchError, map } from 'rxjs/operators';
import { IIntegrationItem } from '@interfaces/iintegration-item';
import { IUserIntegration } from '@interfaces/iuser-integration';
import { INTEGRATION_ICONS, LOGIN_PROVIDERS } from '../utils/integrations';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { IntegrationSignInResult } from '@interfaces/integration-signin-result';

@Injectable()
export class DataIntegrationsService {
  uploadPercentage = new BehaviorSubject<number>(0);
  public lastUploadedFiles: File[] = [];

  constructor(private http: HttpClient, private _auth: SocialAuthService) { }

  getIntegrations(limit?: number): Observable<IIntegration[]> {
    const url = limit ? `${environment.apiUrl}/integrations?limit=${limit}` : `${environment.apiUrl}/integrations`
    return this.http.get<IIntegration[]>(url);
  }

  getUserIntegrations(): Observable<IUserIntegration[]> {
    return this.http.get<IUserIntegration[]>(
      `${environment.apiUrl}/accounts/me/integrations`
    );
  }

  getMergedIntegrations(): Observable<IIntegrationItem[]> {
    return forkJoin([
      this.getIntegrations(),
      this.getUserIntegrations()
    ]).pipe(
      map(
        ([
          integrations, userIntegrations
        ]) => this.mergeIntegrationsData(integrations, userIntegrations)
      )
    );
  }

  postFiles(files: File[]) {
    this.lastUploadedFiles = files;

    const form: FormData = new FormData();
    files.forEach((file, i) => form.append(`files[${i}]`, file, file.name));

    const req = new HttpRequest('POST', `${environment.apiUrl}/accounts/me/integrations/ebook`,
      form, {
      reportProgress: true,
    });

    this.http.request(req).pipe(catchError(e => throwError(e))).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          this.uploadPercentage.next(Math.round(100 * (event.loaded / event.total)));
        default:
          break;
      }
    });
  }

  mergeIntegrationsData(
    integrations: IIntegration[],
    userIntegrations: IUserIntegration[]): IIntegrationItem[] {
    return integrations.map(
      (integration: IIntegration) => {
        const userIntegration = userIntegrations.find(
          (c: IUserIntegration) => c.integrationId === integration._id
        );
        const userEnabled = !!userIntegration;
        const userIntegrationId = userIntegration ? userIntegration._id : null;
        return {
          ...integration,
          image: INTEGRATION_ICONS[integration.name],
          userEnabled,
          userIntegrationId,
        };
      }
    )
  }

  signIn(integration: IIntegrationItem): Observable<IntegrationSignInResult> {
    const { name, settings } = integration;
    const providerId = LOGIN_PROVIDERS[name];
    return from(this._auth.signIn(providerId, settings))
      .pipe(catchError(err => {
        console.error(err);
        throw (err.error ? err.error : err);
      }))
      .pipe(map(
        (user: SocialUser) => {
          const { authorizationCode } = user;
          return {
            integrationId: integration._id,
            authorizationCode
          };
        }
      ));
  }

  connect({ integrationId, authorizationCode }: IntegrationSignInResult): Observable<IUserIntegration> {
    return this.http.post<IUserIntegration>(
      `${environment.apiUrl}/accounts/me/integrations`,
      {
        integrationId,
        authorizationCode
      }
    ).pipe(catchError(err => {
      console.error(err);
      const error = err.error ? err.error.message : err.message
      throw (error);
    }));
  }

  disconnect(id: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiUrl}/accounts/me/integrations/${id}`
    ).pipe(catchError(err => {
      console.error(err);
      const error = err.error ? err.error.message : err.message
      throw (error);
    }));
  }
}



