import { Component, ViewEncapsulation, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { AccountService } from './services/account.service';
import { UiService } from './shared/actions/ui.service';
import { combineLatest } from 'rxjs/operators/combineLatest';
// declare google analytics
declare const ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  display = false;
  displayBlur = false;
  constructor(
    private _router: Router,
    private _accountService: AccountService,
    private _uiService: UiService,
    private cd: ChangeDetectorRef
  ) {
    ga('create', environment.gacode, 'auto');

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  ngOnInit(): void {
    this._accountService.refreshAccount();
    this._uiService.showOverlay$
      .pipe(
        combineLatest(this._uiService.showBlur$)
      )
      .subscribe(([overlay, blur]) => {
        if (this.display !== overlay) {
          this.display = overlay;
          this.cd.detectChanges();
        } else if (this.displayBlur !== blur) {
          this.displayBlur = blur;
          this.cd.detectChanges();
        }
      });

    this.sendAuthToExtension();
  }

  displayOverlay() {
    return !!this.display;
  }

  sendAuthToExtension() {
    if (window.chrome && window.chrome.runtime) {
      const token = this._accountService.getToken();
      if (token) {
        window.chrome.runtime.sendMessage(environment.extensionId, { event: 'sync_token', data: { token: token } },
          function (response) {
            console.log('response from extension:', response)
          });
      }
    }
  }

}
