import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Channel } from '../../interfaces';
import { SetActiveChannel, LoadChannels } from '../actions/channel.action';

export interface ChannelStateModel {
  channels: Array<Channel>;
  active: Channel;
}

@State<ChannelStateModel>({
  name: 'channel',
  defaults: {
    channels: new Array<Channel>(),
    active: {
      _id: undefined,
      updatedAt: undefined,
      createdAt: undefined,
      title: undefined,
      accountId: undefined,
      workspaceId: undefined,
      invites: new Array<string>(),
      members: [{
        _id: undefined,
        email: undefined,
        firstName: undefined,
        lastName: undefined,
      }],
      __v: undefined,
      permanent: false
    }
  }
})
export class ChannelState {

  @Selector()
  public static getChannels(state: ChannelStateModel) {
    return state.channels;
  }

  @Selector()
  public static getActive(state: ChannelStateModel) {
    return state.active;
  }

  @Action(SetActiveChannel)
  setActiveChannel(ctx: StateContext<ChannelStateModel>, { payload }: SetActiveChannel) {
    ctx.patchState({
      active: payload
    });
  }

  @Action(LoadChannels)
  loadChannels(ctx: StateContext<ChannelStateModel>, { payload }: LoadChannels) {
    ctx.patchState({
      channels: payload
    });
  }
}
