import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AccountService } from './account.service';
// import { Account } from '../interfaces';



@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _accountService: AccountService,
  ) { }

  canActivate(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const token = this._accountService.getToken();

    if (!!token) {
      const path = routeSnapshot.url.length > 0 ? routeSnapshot.url[0].path : '';
      const workspaceId = this._accountService.getDefaultWsId();

      if (!!workspaceId) {
        // If path is not empty eg: notebook or dashboard,
        // redirect to path on default workspace
        if (path !== '') {
          this._router.navigate(['/', workspaceId, path]);
        } else {
          this._router.navigate(['/', workspaceId, 'notebook']);
        }
        return false;
      }
    }
    this._router.navigate(['/', 'sign-in']);
    return false;
  }
}
