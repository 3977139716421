import {
  Component,
  // Input,
  OnInit,
  OnDestroy,
  // ViewChildren,
  // QueryList,
  // ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  // FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Subscription } from 'rxjs';
// import { debounceTime, combineLatest } from 'rxjs/operators';
// import { WorkspacesService } from '../../../../../services/workspaces.service';
import { Account } from '../../../../../interfaces';
import { Store } from '@ngxs/store';
// import { SetActiveWorkspace } from '../../../../../store/actions/workspace.action';
// import { WsInviteModalComponent } from '../ws-invite-modal/ws-invite-modal.component';
// import { environment } from '../../../../../../environments/environment';
// import { ConfirmationModalComponent } from '@shared/ui-components/confirmation-modal/confirmation-modal.component';
import { NotificationService } from '@services/notification.service';
import { AccountService } from '@services/account.service';
import { SetCurrentUser } from 'src/app/store/actions/account.action';

@Component({
  selector: 'app-username-modal-content',
  templateUrl: './username-modal.component.html',
  styleUrls: ['./username-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsernameModalComponent implements OnInit, OnDestroy {
  loading: boolean;
  success: boolean;

  updateUsernameForm: FormGroup;
  subscription = new Subscription();
  val = '';
  account: Account;

  constructor(
    public activeModal: NgbActiveModal,
    private _store: Store,
    private _account: AccountService,
    private _formBuilder: FormBuilder,
    private _notify: NotificationService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.success = false;

    this.updateUsernameForm = this._formBuilder.group({
      username: ['', Validators.required]
    });
  }

  ngOnDestroy() {}

  submitUsernameForm() {
    this._account.update(this.updateUsernameForm.value).subscribe(
      (response: Account) => {
        this.account = response;
        this._notify.success(
          `Updated`,
          'Your account has been updated successfully!',
          `${response.firstName} ${response.lastName || ''}`
        );
        this._store.dispatch(new SetCurrentUser(response));
        this.activeModal.dismiss(false);
      },
      error => {
        this._notify.error(
          `Error`,
          `${
            error.error && error.error.message
              ? error.error.message
              : 'We are currently having trouble updating your account information.'
          }`
        );
      }
    );
  }
}
