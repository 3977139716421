import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-module-create-account-property',
  templateUrl: './module-create-account-property.component.html',
  styleUrls: ['./module-create-account-property.component.scss']
})
export class ModuleCreateAccountPropertyComponent implements OnInit {

  @Input() properties: any;
  @Output() loadProfiles = new EventEmitter();
  @Output() gotoPage = new EventEmitter();

  constructor() { }

  selectAccountProperty(propertyId): void {
    this.loadProfiles.emit(propertyId);
  }

  navigate(page: number): void {
    this.gotoPage.emit(page);
  }

  ngOnInit() {
  }

}
