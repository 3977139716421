import { Note } from '../../interfaces';

export class SetActiveNote {
  static readonly type = '[Note] SetActiveNote';

  constructor(public payload: Note) {}
}

export class LoadNotes {
  static readonly type = '[Note] LoadNotes';

  constructor(public payload: Array<Note>) {}
}
