import { Injectable, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NotificationsService, NotificationType, Notification } from 'angular2-notifications';
import { take, filter, map } from 'rxjs/operators';

import { WorkspacesService } from './workspaces.service';
import { UtilService } from './util.service';
import { NoteComment } from '@interfaces/index';
import { NotesService } from './notes.service';
import { combineLatest } from 'rxjs';

@Injectable()
export class NotificationService {
  noteId: string;
  constructor(
    private _router: Router,
    private _notify: NotificationsService,
    private _utilService: UtilService,
    private _workspaceService: WorkspacesService,
    private _route: ActivatedRoute
  ) {}

  newNote(data): void {
    const { workspace, owner, note } = data;
    const channel = note.channels[0];
    this._workspaceService.getActive$
      .pipe(
        take(1)
      )
      .subscribe(currentWs => {
        const toast = this.info(
          note.title,
          `${owner.firstName} added a note to ${channel.title}`,
          workspace.name,
          `${owner.username}`
        );
        toast.click.subscribe(() => {
          if (note.workspaceId !== currentWs._id) {
            this._utilService.clearActiveRedux();
          }
          this._router.navigate(['/', note.workspaceId, 'notebook', channel._id, 'notes', note._id ]);
        });
      });
  }

  private displayDiscussionNotification(data: NoteComment, title: string) {
    const { workspace, owner, note, message } = data;
    const [channel] = note.channels;
    combineLatest(
      this._workspaceService.getActive$,
      this._route.firstChild.firstChild.firstChild.firstChild.firstChild.params // I know it's long, it was this or keep overloading redux
    ).pipe(
      take(1),
      filter(([, params]) => note._id !== params.noteId)
    ).subscribe(([currentWs]) => {
      const toast = this.info(
        title,
        message.body,
        workspace.name,
        `${owner.username}`
      );
      toast.click.subscribe(() => {
        if (note.workspaceId !== currentWs._id) {
          this._utilService.clearActiveRedux();
        }
        this._router.navigate(['/', note.workspaceId, 'notebook', channel, 'notes', note._id, 'discuss' ]);
      });
    });
  }

  firstComment(data: NoteComment): void {
    const { owner, note } = data;
    this.displayDiscussionNotification(data, `${owner.username} started a discussion in ${note.title}`);
  }

  newMention(data: NoteComment): void {
    const { owner, note } = data;
    this.displayDiscussionNotification(data, `${owner.username} mentioned you in ${note.title}`);
  }

  updateMention(data: NoteComment): void {
    const { owner, note } = data;
    this.displayDiscussionNotification(data, `${owner.username} mentioned you in ${note.title}`);
  }

  error(title: TemplateRef<any> | string, content: TemplateRef<any> | string, override?: any): Notification {
    return this._notify.error(title, content, override);
  }

  success(title: TemplateRef<any> | string, content: TemplateRef<any> | string, fullName: string = '', override?: any): Notification {
    const options = this.getOverride({ icons: { success: this.getInitials(fullName) } }, override);
    return this._notify.success(title, content, options);
  }

  warning(title: TemplateRef<any> | string, content: TemplateRef<any> | string, override?: any): Notification {
    return this._notify.warn(title, content, override);
  }

  info(title: string, content: string, workspace: string, fullName: string, override?: any): Notification {
    const html = `
      <div class="notification-info-ws">
        <img src="../assets/images/icons/case@3x.svg"/>
        <span class="info-ws-text">${workspace}</span>
      </div>
      <div class="notification-content">
        <div class="notification-info-title">
          ${title}
        </div>
        <div class="notification-info-content">
          ${content}
        </div>
        <div class="icon">
          ${this.getInitials(fullName)}
        </div>
      </div>
    `;
    return this._notify.html(html, NotificationType.Info, override);
  }

  showNotification(title: TemplateRef<any> | string, content: TemplateRef<any> | string, type: NotificationType,  override?: any): Notification {
    return this._notify.create(title, content, type, override);
  }

  private getInitials(fullName: string): string {
    const initial = this._utilService.getInitials(fullName);
    return `<span class="user-avatar avatar-notification">${initial}</span>`;
  }

  private getOverride(defaults: any, override?: any): any {
    return Object.assign(defaults, override || {});
  }
}
